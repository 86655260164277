<template>
  <div class="main-content affiliate affiliateSign">
      <h4 class="textTitle">註冊成為聯盟夥伴</h4>
    <div class="banner">
      <div class="banner-ico"></div>
    </div>

   <div class="affiliateSign-form">
     <el-form
         ref="contactForm"
         :model="contactForm"
         :rules="contactRules"
     >
       <el-form-item prop="first_name" label="名字">
         <el-input v-model="contactForm.first_name"></el-input>
       </el-form-item>
       <el-form-item prop="last_name" label="姓氏">
         <el-input v-model="contactForm.last_name"></el-input>
       </el-form-item>
       <el-form-item prop="email" label="電子郵件">
         <el-input v-model="contactForm.email"></el-input>
       </el-form-item>
       <el-form-item prop="message" label="訊息">
         <el-input
             v-model="contactForm.message"
             type="textarea"
             :rows="16"
             resize="none"
         ></el-input>
       </el-form-item>
       <el-form-item>
         <button class="button affiliate-btn" type="button" :disabled="butDisabled" @click="submit">
           提交
         </button>
       </el-form-item>
     </el-form>
   </div>
  </div>
</template>

<script>
import {emailContactUs} from "@/request/api"
export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      butDisabled: false,
      contactForm: {
        // problem_class_id: null,
        first_name: '',
        last_name: '',
        email: '',
        message: ''
      },
      contactRules: {
        // problem_class_id: { required: true, message: 'Class is required', trigger: 'blur' },
        first_name: { required: true, message: '名字是必填項', trigger: 'blur' },
        last_name: { required: true, message: '姓氏是必填項', trigger: 'blur' },
        email: [
          { required: true, message: '電子郵件是必填項', trigger: 'blur' },
          { type: 'email', message: '請輸入正確的電子郵件地址', trigger: 'blur' }
        ]
      }
    };
  },
  created() {

  },
  methods: {

    submit() {
      var that = this
      this.$refs.contactForm.validate(valid => {
        if (valid) {
          // //發送聯繫email
          let data = that.contactForm;
          let myheaders = {headers : {"pc":true,"content-type":"multipart/form-data"}};
          emailContactUs(data, myheaders).then(function(res){
            if (res.code == 200 || res.code == "200" || res.code == "ok") {
              that.$alert(res.msg, '', {
                confirmButtonText: '確定',
                customClass:'affiliate-alert',
                callback: action => {
                  // 確定後執行**
                  that.$router.push('/');
                }
              });
            }
            else {
              that.$message.error(res.msg);
            }
          });
        } else {

          return false
        }
      })
    },
  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
p{color: #ffffff;}
</style>
