<template>
  <div class="main-content" style="padding-top: 0px">
    <div class="box-left-logo">
      <img @click="$router.push('/')" src="@/assets/image/header/logo.png" alt="logo" style="cursor: pointer">
    </div>
    <div class="exchange">
      <img src="@/assets/image/erwei.png" alt="erwei"/>
      <h3>兌換QR Code</h3>
    </div>
    <div class="exchangeBody">
      <div class="left">
        <img class="Pc" src="@/assets/image/perPc.png" alt="perPc"/>
        <img class="Mb" src="@/assets/image/perMb.png" alt="perMb"/>
        <div>
          <p>提醒您兌換成eSIM QR code後，</p>
          <p>需在30天內將其安裝到您的手機上並使用完畢</p>
          <p>否則QR code及行動方案將在此後過期</p>
          <p>方案時間按安裝時起算</p>
        </div>
      </div>
      <div class="right">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
          <el-form-item label="電子信箱" prop="email">
            <el-input v-model="ruleForm.email" placeholder="輸入電子郵件"></el-input>
          </el-form-item>
          <el-form-item label="確認電子信箱" prop="perEmail">
            <el-input v-model="ruleForm.perEmail" placeholder="重複輸入電子郵件"></el-input>
          </el-form-item>
          <el-form-item label="兌換碼" prop="snPin">
            <el-input v-model="ruleForm.snPin" placeholder="輸入兌換碼"></el-input>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="submitForm('ruleForm')">確認</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {queryResult, submit_exchange} from "@/request/api";
import {Loading} from "element-ui";

export default {
  name: 'index.vue',
  data() {
    return {
      ruleForm: {
        email: '',
        perEmail: '',
        snPin: ''
      },
      loadingInstance: null,
      snpin_id: null,
      rules: {
        email: [
          {required: true, message: '請輸入信箱', trigger: 'blur'},
          {type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change']}
        ],
        perEmail: [
          {required: true, message: '請再次輸入信箱', trigger: 'blur'},
          {type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change']},
          {validator: this.validateEmails, trigger: 'blur'}
        ],
        snPin: [
          {required: true, message: '請輸入兌換碼', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    validateEmails(rule, value, callback) {
      if (value !== this.ruleForm.email) {
        callback(new Error('兩個信箱不一致'));
      } else {
        callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.send()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    async send() {
      let res = await submit_exchange(this.ruleForm)
      if (res.code == 200) {
        this.snpin_id = res.data.snpin_id
        this.loadingInstance = Loading.service({
          lock: true,
          text: '兌換中......'
        })
        setTimeout(this.getQrcodeUrl(), 5000)
      } else {
        this.$message.warning(res.msg)
      }
    },
    async getQrcodeUrl() {
      let res = await queryResult({snpin_id: this.snpin_id})
      if (res.code == 200) {
        await this.$router.push({
          path: 'exchangeDetail',
          query: {obj: JSON.stringify(res.data), email: this.ruleForm.email}
        })
        this.loadingInstance.close()
      } else {
        setTimeout(this.getQrcodeUrl, 5000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.exchange {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 80px;
  margin-top: 80px;

  h3 {
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
    font-weight: bolder;
  }
}

.exchangeBody {
  padding: 80px 40px;
  background: #1E1E1E;
  border-radius: 10px;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 40px;

  .left {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 20px;

    .Pc {
      display: block;
    }

    .Mb {
      display: none;
    }

    p {
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }

    p:nth-child(3) {
      color: #FFC700;
    }
  }

  .right {
    flex-grow: 1;

    ::v-deep .el-form-item__label {
      color: #ffffff;
      font-size: 16px;
      margin-top: 10px;
    }

    ::v-deep .el-input__inner {
      background: #2E2E2E;
      border: none;
      border-radius: 10px;
      margin-top: 10px;
      color: #ffffff;
    }

    .btn {
      text-align: right;
      margin-top: 40px;
    }

    .el-button {
      background: #0B57C9;
      color: #ffffff;
      border: none;
      padding: 7px 30px;
      border-radius: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .exchange {
    margin-top: 40px;
    margin-bottom: 0px;

    h3 {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .exchangeBody {
    background: transparent;
    padding: 30px 20px;

    .left {
      display: block;
      text-align: center;
      width: 100%;
      padding-top: 0px;

      img {
        margin: 20px auto;
      }

      .Pc {
        display: none;
      }

      .Mb {
        display: block;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .right {
      ::v-deep .el-form-item__label {
        display: none;
      }

      ::v-deep .el-form-item__content {
        margin-left: 0px;
      }

      .btn {
        text-align: center;
      }
    }
  }
}
</style>
