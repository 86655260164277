<template>
  <div class="product search">
    <div class="home-box">
      <h1 class="title">搜尋目的地</h1>
      <div class="search-box">
        <el-input placeholder="" v-model="search" class="input-with-select" @keyup.enter.native="seachEnter">
          <div slot="append" class="btn-img" @click="seachEnter"><img src="../../assets/image/search/search.png"/></div>
        </el-input>
      </div>
      <div class="search-history">
        <div class="_item" @click="clickSeach(item)" v-for="(item,index) in reverseSearchList" :key="index">
          <div class="_box">{{item}}</div>
        </div>
      </div>
      <div class="search-result"><span>{{searchTwo }}</span>尋找 {{dataList.length}} 通過搜索查找相關產品</div>
      <div class="home-box-list">
        <div class="list-div" v-for="(item, index) in dataList" :key="index" @click="toDetails(item)">
          <div class="list-div-img">
            <img class="img" :src="item.image" alt="">
            <div class="shop" @click.stop="toShop(item)">
              <img src="@/assets/image/icon/icon-shop.png" alt="">
            </div>
            <div class="love" @click.stop="toLove(item)" v-if="false">
              <img src="@/assets/image/icon/icon-love.png" alt="">
            </div>
          </div>
          <h2 class="list-div-h2">{{item.h2}}</h2>
          <h2 class="list-div-h2">{{item.sketch}}</h2>
          <h3 class="list-div-h3">{{item.name}}</h3>
          <h4 class="list-div-h4">
            <!-- ~ USD <span>{{'$ ' + item.max_price}}</span> -->
           <span>{{'$NT ' + item.min_price.toLocaleString()}}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件

import "swiper/dist/css/swiper.css";
import {getProduct, getCurrentRate} from '@/request/api'
import {setSearchList} from "../../utils/search";
import {mapState} from "vuex";
export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      searchTwo:'',
      // 产品列表
      dataList: [

      ],
      rate:0,// 匯率
    };
  },
  computed: {
    ...mapState(['searchList']),
    reverseSearchList() {
      const searchList = [...this.searchList]
      return searchList.reverse()
    }
  },
  created() {
    if (this.$route.query.content) {
      this.search = this.$route.query.content;
    }
    this.searchTwo = this.search;
    this.getProductList()
    this.getCurrentRate()
  },
  methods: {
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入购物车
    toShop(item) {
      console.log(2222)
    },
    // 加入我喜欢
    toLove(item) {

    },
    //獲取產品列表
   async getProductList(){
    let data = {
      type_id:this.$route.query.typeId,
      country_id:this.$route.query.areaId,
      txt:this.search
    }
       let res = await getProduct(data);
       this.dataList = res.data.plan;
       this.searchTwo = this.search;
    },

    seachEnter(){
      setSearchList(this.search)
       this.getProductList();
    },
    clickSeach(value){
      console.log(value)
      this.search = value
      this.seachEnter()
    },
    async getCurrentRate() {
      const res = await getCurrentRate({})
      this.rate = Number(res.data.rate)
    }
  },
  watch:{
     $route:function(newvalue,oldvalue){
        if(newvalue != oldvalue){
           this.getProductList()
        }
     }
  }
}
</script>

<style lang="less" scoped>
.product {
  padding-bottom: 90px;
  .home-box {
    margin: 40px auto 0;
    width: 1174px;
    .home-box-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 46px;
        color: #FFFFFF;
      }
      div {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .home-box-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .list-div {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 45px;
        width: 278px;
        .list-div-img {
          width: 100%;
          height: 186px;
          border-radius: 5px;
          position: relative;
          margin-bottom: 17px;
          .img {
            width: 100%;
            height: 100%;
          }
          .shop {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 23px;
              height: 23px;
            }
          }
          .love {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .list-div-h2 {
          padding: 0 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #00A2FD;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .list-div-h3 {
          padding: 2px 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
        }
        .list-div-h4 {
          padding: 0 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #616161;
          color: #FFC700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            font-size: 16px;
            line-height: 23px;
          }
          i {
            font-size: 12px;
            margin-left: 10px;
            font-style: normal;
          }
        }
      }
      .list-div:nth-child(4n+4) {
        margin-right: 0;
      }
      .list-div:hover {
        .list-div-img {
          .shop {
            display: flex;
          }
          .love {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .product {
    .home-box {
      width: 1000px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .product {
    .home-box {
      width: 780px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
          .list-div-img {
            width: 100%;
            height: 164px;
          }
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .product {
    .home-box {
      padding: 0 20px;
      width: calc(100% - 40px);
      .home-box-title {
        margin-bottom: 12px;
        h1 {
          font-size: 16px;
          line-height: 23px;
        }
        div {
          font-size: 12px;
          line-height: 17px;
          img {
            width: 23px;
            height: 23px;
          }
        }
      }
      .home-box-list {
        .list-div {
          width: calc((100% - 20px) / 2);
          .list-div-img {
            width: 100%;
            height: 105px;
          }
          .list-div-h2 {
            padding: 12px 0 0;
            font-size: 14px;
            line-height: 14px;
          }
          .list-div-h3 {
            padding: 3px 0 0;
            font-size: 14px;
            line-height: 20px;
          }
          .list-div-h4 {
            padding: 0;
            font-size: 10px;
            line-height: 15px;
            span {
              font-size: 14px;
              line-height: 20px;
            }
            i {
              font-size: 10px;
              line-height: 20px;
              margin-left: 6px;
            }
          }
        }
        .list-div:nth-child(3n+3) {
          margin-right: 20px;
        }
        .list-div:nth-child(2n+2) {
          margin-right: 0;
        }
        .list-div:hover {
          .list-div-img {
            .shop {
              display: none;
            }
            .love {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
