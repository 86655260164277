<template>
  <div class="main-content">
    <div class="top">
      <img :src="buzzDetail.banner_pc" alt="" width="100%" height="100%" style="border-radius: 10px 10px 0 0"/>
      <div class="topBon">
        <div class="btn">
          <span v-for="(item,i) in buzzDetail.tag_list" :key="i" :style="{background: item.id == tag_id?'#00A2FD':'transparent',color:item.id != tag_id?'#00A2FD':'#ffffff'}">{{ '#'+ item.txt }}</span>
        </div>
        <h3>{{ buzzDetail.title }}</h3>
        <div class="bq">
          <img src="@/assets/image/rl.png" alt=""/>
          <span>{{ buzzDetail.publishDate ? buzzDetail.publishDate.substr(0, 10).replaceAll('-', '.') : '' }}</span>
        </div>
      </div>
    </div>
    <div class="content" v-html="buzzDetail.content">

    </div>
    <div class="detailBon">
      <div class="wz">
        <h3>閱讀更多</h3>
        <img src="@/assets/image/wz.png" alt=""/>
      </div>
      <div class="wzContent">
        <div class="wzBody" v-for="(item,i) in hotList.slice(0,3)" :key="i" @click="$router.push({path:`/buzzDetail?id=${item.id}&tag_id=${tag_id}`})">
          <div class="contentTwo">
            <div class="topTwo">
              <img :src="item.banner_pc" alt="" width="100%" height="220px"/>
              <p class="firstP">{{item.tag_list[0].txt}}</p>
            </div>
            <div class="buzzBqBody">
              <h3>{{item.title}}</h3>
              <p class="detail">{{item.synopsis}}</p>
            </div>
          </div>
          <div class="bqTwo">
            <img src="@/assets/image/rl.png" alt=""/>
            <span>{{item.publishDate ?item.publishDate.substr(0,10).replaceAll('-','.'):''}}</span>
            <span v-for="(it,index) in item.tag_list" :key="index" :style="{color:it.id == tag_id?'#00A2FD':''}">{{ '#'+it.txt }}</span>
          </div>
        </div>
      </div>

    </div>

    <el-backtop :right="100">
      <div
          style="{
        height: 100%;
        width: 100%;
        background-color: #666666;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
         border-radius: 5px;
         color: #ffffff;
      }"
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import {buzz_detail, buzz_list} from "@/request/api";

export default {
  name: "buzzDetail.vue",
  data() {
    return {
      buzzDetail: {},
      hotList: [],
      tag_id: null
    }
  },
  mounted() {
    this.tag_id = this.$route.query.tag_id
    this.getBuzzDetail()
    this.getHotList()
  },
  methods: {
    async getBuzzDetail() {
      let res = await buzz_detail({buzz_id: this.$route.query.id})
      this.buzzDetail = res.data
    },
    async getHotList() {
      let res = await buzz_list({page: 1})
      this.hotList = res.data.list
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.tag_id = this.$route.query.tag_id
          this.getBuzzDetail()
        }
      }
    }
  },
}
</script>

<style scoped lang="less">
.top {
  margin-bottom: 40px;

  .topBon {
    width: 100%;
    padding: 20px 40px;
    background: #ffffff;
    border-radius: 0 0 10px 10px;

    .btn {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      span {
        border: 1px solid #00A2FD;
        color: #00A2FD;
        font-size: 16px;
        line-height: 20px;
        padding: 5px 20px;
        border-radius: 25px;
        cursor: default;
      }
    }

    h3 {
      font-size: 35px;
      line-height: 48px;
      color: #272727;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
    }

    .bq {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 16px;
        color: #616161;
      }
    }
  }
}

.content {
  color: #ffffff;
  ::v-deep img {
   max-width: 100%;
    object-fit: contain;
  }
}

.detailBon {
  margin-top: 60px;
  padding-top: 40px;
  border-top: 1px solid #ffffff;
  margin-bottom: 40px;

  .wz {
    display: flex;
    align-items: center;

    h3 {
      font-size: 36px;
      line-height: 44px;
      color: #ffffff;
      margin-right: 20px;
    }
  }

  .wzContent {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;

    .wzBody {
      width: 31%;
    }

    .contentTwo {
      background: #ffffff;
      border-radius: 10px;

      .topTwo {
        position: relative;
        cursor: pointer;

        img {
          border-radius: 10px 10px 0 0;
        }

        .firstP {
          display: inline-block;
          position: absolute;
          bottom: -20px;
          right: 0;
          color: #ffffff;
          font-size: 21px;
          line-height: 30px;
          background: #00A2FD;
          border-radius: 25px 0 0 25px;
          padding: 5px 20px;
        }
      }

      .buzzBqBody {
        padding: 20px;
        cursor: pointer;
      }

      h3 {
        color: #272727;
        font-size: 25px;
        line-height: 30px;
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .detail {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        color: #666666;
        margin-bottom: 10px;
      }
    }
  }

  .bqTwo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    span {
      font-size: 16px;
      color: #ffffff;
    }

  }
}

@media screen and(max-width: 1024px) {
  .wzBody {
    width: 48% !important;
  }
}

@media screen and(max-width: 800px) {
  .top .topBon h3{
    font-size: 20px;
    line-height: 24px;
  }
  .wzBody {
    width: 100% !important;
  }

  .detailBon .wzContent .contentTwo .topTwo .firstP {
    font-size: 16px;
    line-height: 20px;
  }

  .detailBon .wzContent .contentTwo h3 {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
