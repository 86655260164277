<template>
  <div class="details">
    <div class="main-content">
      <div class="terms-title">
        隱私政策
      </div>
      <div class="terms-body">
        <p>eSImart  致力於維護國際個人資料隱私保護標準，並在可能的情況下超越這些標準，同時遵守台灣《個人資料保護法》的要求。我們確保我們的員工嚴格遵循安全和保密標準。</p>
        <h5>1. 資料收集的原因</h5>
        <p></p>
        <p>我們收集個人資料和信息以提供和增強我們的服務。這包括但不限於您的姓名、地址、聯絡資料、身份證號碼和支付信息。我們收集信息的目的包括：</p>
        <p>· 訂閱服務與支援，包括更新與通知。</p>
        <p>· 根據用戶需求量身定做服務並提升用戶體驗。</p>
        <p>· 系統維護與故障排除。</p>
        <p>· 行銷和推廣目的。</p>

        <h5>2. 資訊披露</h5>
        <p>您確認您所提供的資訊可能會被存取、披露、使用或轉移給各種實體，包括第三方服務提供商、金融機構、債務收集機構以及法律或監管要求的其他相關方。</p>

        <h5>3. 用戶偏好 / 選擇退出</h5>
        <p>我們提供選項，讓您可以選擇退出接收來自eSImart 的非請求通訊。如果您希望停止接收這類通訊，請聯繫我們的客戶關係部門，我們將會把您從我們的聯繫名單中移除。</p>

        <h5>4. 資訊收集</h5>
        <p>使用「Cookies」我們可能會使用cookies來收集網站使用情況的資訊。使用者可以禁用cookies而不影響導航，但可能會影響某些功能。</p>

          <h5>5. 濫發訊息</h5>
        <p>我們對垃圾郵件採取嚴格政策，鼓勵用戶報告此類事件。如果垃圾郵件源自eSImart 帳戶，我們將採取適當行動。</p>

            <h5>6. 安全</h5>
        <p>我們已經實施了安全措施，以保護收集的數據免受未經授權的訪問。</p>

         <h5>7. 保留期限</h5>
        <p>我們保留個人資料，以便在達成特定目的所需的時間內使用。</p>

        <h5>8. 隱私政策更新</h5>
        <p>我們更新此政策以反映服務和營運環境的變化。</p>

        <h5>9. 其他權利</h5>
        <p>您根據台灣個人資料（隱私）條例保留權利，包括存取、更正和禁止使用資料的權利。</p>

        <h5>10. 保證與責任限制</h5>
        <p>用戶使用網站的責任；eSImart 對於產品、服務或資訊使用所產生的後果不承擔任何責任。</p>

        <h5>11. 聯絡我們</h5>
        <p>有關數據存取、更正或查詢的請求，請聯繫我們，電子郵件地址為service@eSImart .com。</p>

        <h5>12. 版權聲明與免責聲明</h5>
        <p>12.1 版權聲明</p>
        <p>商標、版權和知識產權歸屬於eSImart 或其他相關方。未經同意，禁止使用。</p>

        <h5>12.2 免責聲明</h5>
        <p>本網站使用為用戶的責任。eSImart  不承擔因從本網站或鏈接的第三方網站獲得的產品、服務或信息使用而導致的任何後果的責任。</p>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.terms-title{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 32px;line-height: 46px;color: #FFFFFF;text-align: center;margin-bottom: 35px;}
.terms-body{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;color: #FFFFFF;}
.terms-body h5{color: #00A2FD;margin-top: 20px;}
.terms-body h6{}
@media screen and (max-width: 800px) {
  .terms-title{margin-bottom: 15px;}
  .details{padding-bottom: 40px;}
}
</style>
