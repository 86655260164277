<template>
  <div class="main-content aboutUs">
    <h4 class="textTitle"><span>關於 eSImart</span></h4>
    <div class="about-box">
      <div class="who">
        <div class="_title">我們是誰?</div>
        <div class="_box">
          <p>歡迎來到 eSImart！我們是您尋找網路漫遊方案的絕佳選擇。作為一家充滿活力且擁有靈活應變能力的公司，我們致力於為您提供無比便捷且絕對可靠的解決方案。在 eSImart，我們以專業知識和熱情為您提供最優質的服務，讓您的網路漫遊需求得以充分滿足。</p>
        </div>
      </div>
      <div class="items">
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about1.png" /></div>
          <div class="_box">
            <div class="_title">我們的核心價值</div>
            <div class="_body">
              <p>在eSIMart，我們的核心價值圍繞著提供客戶更靈活的網路流量方案。我們相信每位客戶都是獨一無二的，顧客的每次旅遊是彌足珍貴的體驗，因此我們努力提供不同個性化的服務，以滿足您的需求。</p>
            </div>
          </div>
        </div>
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about2.png" /></div>
          <div class="_box">
            <div class="_title">我們的願景</div>
            <div class="_body">
              <p>我們的願景是成為您在eSIM領域的值得信賴的夥伴。我們的目標不僅是通過提供優秀的產品來引領行業，還包括積極主動和熱情的客戶服務。我們期待著找到最合適的國家方案，讓您的旅行和上網體驗更加流暢和愉快。</p>
            </div>
          </div>
        </div>
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about3.png" /></div>
          <div class="_box">
            <div class="_title">我們的故事</div>
            <div class="_body">
              <p>成立於2021年，eSImart旨在解決那些在出發前常常忘記購買SIM卡或在旅途中遺失實體SIM卡的用戶所面臨的挑戰。eSIM的新科技，我們確保您在30分鐘內收到訂單，大大簡化了購買流程，並為您的數位漫遊需求提供更方便的選擇。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="globalP">
        <div class="_title">全球合作夥伴</div>
        <div class="_body">
          <p>我們與世界各地的電信業界龍頭領導者合作，例如日本的Softbank、Docomo、KDDI，韓國的SKT和KT，以及東南亞目的地的主要供應商，如TrueMove和Viettel。合作伙伴還延伸至歐洲，包括KPN、3U和Giffgaff。我們廣泛的網絡覆蓋了亞洲、歐洲、美洲和大洋洲的100多個國家和地區。在我們致力於全球覆蓋的同時，eSIMart致力於為旅行者提供可靠且可定制的網絡服務。</p>
        </div>
      </div>
      <div class="thank">
        <p>感謝您選擇eSIMart。<br />
          讓我們使您的旅程和網路使用體驗更加順暢！</p>
      </div>
    </div>









  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      tabIndex:1,
      searchTwo:'',
      ios1:[
        // {
        //   id: 1,
        //   name: "Use Camera to scan QRcode from eSImart",
        //   img: require("@/assets/image/works/ios1.jpg")
        // },
        // {
        //   id: 2,
        //   name: "Tap Continue to Activate eSIM at your phone",
        //   img: require("@/assets/image/works/ios2.jpg")
        // },
        // {
        //   id: 3,
        //   name: "Add cellular plan and label it",
        //   img: require("@/assets/image/works/ios3.jpg")
        // },
        // {
        //   id: 4,
        //   name: "Choose ‘Primary’ for Default Line",
        //   img: require("@/assets/image/works/ios4.jpg")
        // },
        // {
        //   id: 5,
        //   name: "Choose ‘Primary’ for iMessage & FaceTime",
        //   img: require("@/assets/image/works/ios5.jpg")
        // },
        // {
        //   id: 6,
        //   name: "Choose ‘Primary’ for Cellular Data",
        //   img: require("@/assets/image/works/ios6.jpg")
        // },
      ],
      // 产品列表
      dataList: [

      ],
      ios:true
    };
  },
  created() {

  },
  methods: {

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>

</style>
