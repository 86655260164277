<template>
  <div class="closing main-content">
    <div class="crumbs">
      <ul>
        <li><a href="#" @click="$router.push('/')">首頁</a></li>
        <li>結帳</li>
      </ul>
    </div>
    <div class="main">
      <h2 class="cart-title"><div class="_ico"><img src="@/assets/image/cart/closing-title.png" alt="購物車"></div><span>支付結果</span></h2>
      <div class="cart-box">
         <!--付款成功-->
       <div class="result" v-if="$route.query.success">
         <div class="result-img">
           <img src="../../assets/image/cart/payment-success.png" alt="payment-success"/>
         </div>
         <div class="result-body">
           <div class="_txt">付款成功</div>
           <div class="_txt">訂單編號：{{ $route.query.order_no}}</div>
           <div class="_txt">如您購賣產品為eSIＭ</div>
           <div class="_txt">eSIM QR code將會寄送至您的Email：<span class="email">{{ $route.query.user_email }}</span></div>
           <div class="_txt">如您購賣產品為實體卡</div>
           <div class="_txt">我們在收到訂單後將於次日工作天(國定假日順延)於郵局寄出給您，寄出後約3~5工作天您將會收到您購買的SIM卡</div>
           <div class="_txt">30 分鐘後即可至<router-link to='/personal?type=2'>會員中心-我的訂單</router-link>查看eSIM QR Code</div>
         </div>
       </div>
        <!--付款失敗-->
        <div class="result" v-else>
          <div class="result-img">
            <img src="../../assets/image/cart/payment-fail.png" alt="payment-fail" />
          </div>
          <div class="result-body">
            <div class="_txt">付款失敗 </div>
            <div class="_txt">你可以繼續進行<router-link to='/personal?type=2'>會員中心</router-link>繼續支付此訂單</div>
            <div class="_txt">如果您有支付問題，請聯繫我們: <span class="email">service@esimart.com</span></div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Closing',
  data() {
    return {
      num: 1,
      value: '',
      code:'TX8767',
      receipt:1,
      question:false,
      form: {},
      // userinfo: JSON.parse(window.localStorage.getItem('userInfo')),

      options: [
        {
          value: 1,
          label: '選項1'
        },
        {
          value: 2,
          label: '選項2'
        }
      ],
      tagList: ['# 美國', '# 本地卡', '# 按日計價'],

      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '使用方法'
        },
      ]
    }
  },
  mounted() {
    let _this = this
    this.$nextTick(() =>{
      setTimeout(function () {
        if(_this.$route.query.back_data && _this.$route.query.success){
          let decodedBase64String = atob(_this.$route.query.back_data);
          let decodedData = JSON.parse(decodedBase64String);
          let items =  decodedData.plan_list.map(v =>{
            return {
              item_id:v.id,
              item_name:v.name,
              item_category:v.days+'Days',
              item_category2:v.spec,
              item_category3:v.package,
              quantity:v.num,
              price: v.special_amount
            }
          })
          let event_config = {
            currency: "TWD",
            coupon:decodedData.discount_code,
            value: decodedData.total_price,
            items: items
          }
          // console.log(decodedData)
          _this.$gtag.event('purchase', event_config);
          console.log(event_config)
        }
      }, 800)
    })

  },
  methods: {
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num += 1
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 800px) {
  .cart-title {display: flex;padding-top: 40px;}
  .cart-title span {margin-left: 16px;}
}
</style>
<style lang="less" >

</style>
