<template>
  <div class="main-content" style="padding: 0">
    <div class="buzzBody">
      <div class="buzzLeftTop">
        <div class="buzzLeft">
          <div class="content" v-for="(item,i) in buzzList" :key="i"
               @click="$router.push({path:`/buzzDetail?id=${item.id}&tag_id=${tag_id}`})">
            <div class="top">
              <img :src="item.banner_pc" alt="" width="100%" height="220px"/>
              <p class="firstP">{{ item.tag_list[0].txt }}</p>
            </div>
            <div class="buzzBqBody">
              <div class="bq">
                <img src="@/assets/image/rl.png" alt=""/>
                <span>{{ item.publishDate ? item.publishDate.substr(0, 10).replaceAll('-', '.') : '' }}</span>
                <span v-for="(it,index) in item.tag_list" :key="index"
                      :style="{color:it.id == tag_id?'#00A2FD':''}">{{ '#' + it.txt }}</span>
              </div>
              <h3>{{ item.title }}</h3>
              <p class="detail">{{ item.synopsis }}</p>
              <el-button @click="$router.push({path:`/buzzDetail?id=${item.id}&tag_id=${tag_id}`})">了解更多</el-button>
            </div>
          </div>
        </div>
        <div class="checkBtn" v-if="total != this.buzzList.length">
          <span @click="addPage">查看更多</span>
        </div>
      </div>
      <div class="buzzRight">
        <div class="buzzTitle">
          🔥 熱門文章
        </div>
        <div class="rightBody" v-for="(item,i) in hotList.slice(0,5)" :key="i">
          <span>{{ i + 1 }}</span>
          <div class="wenzhang" @click="$router.push({path:`/buzzDetail?id=${item.id}&tag_id=${tag_id}`})">
            <p>{{ item.title }}</p>
            <p>{{ item.synopsis }}</p>
          </div>

        </div>
        <div class="buzzTitleTwo">
          🔥 熱門標籤
        </div>
        <div class="typeBtn">
          <div v-for="(item,i) in typeList" :key="i">
            <span :class="item.id == tag_id?'active':''" @click="setTag(item.id)">{{ item.txt }}</span></div>
        </div>
        <img class="ditu" src="@/assets/image/bon.png" alt=""/>
      </div>
    </div>
    <el-backtop :right="100">
      <div
          style="{
        height: 100%;
        width: 100%;
        background-color: #666666;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
         border-radius: 5px;
         color: #ffffff;
      }"
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import {buzz_list, tag_list, tag_search_buzz_list} from '@/request/api'

export default {
  name: "index.vue",
  data() {
    return {
      typeList: [],
      tag_id: 0,
      page: 1,
      limit: 6,
      total: 0,
      buzzList: [],
      hotList: []
    }
  },
  mounted() {
    this.getTag()
    this.getHotList()
  },
  methods: {
    setTag(id) {
      this.tag_id = id
      this.page = 1
      this.getTagBuzzList()
    },
    addPage() {
      if (this.total != this.buzzList.length) {
        this.page++
        this.getTagBuzzList()
      }
    },

    async getTag() {
      let res = await tag_list()
      this.typeList = res.data.list
      this.getTagBuzzList()
    },

    async getTagBuzzList() {
      let res = await tag_search_buzz_list({tag_id: this.tag_id, page: this.page, limit: this.limit})
      if (this.page == 1) {
        this.buzzList = res.data.list
      } else {
        this.buzzList = [...this.buzzList, ...res.data.list]
      }
      this.total = res.data.total
    },

    async getHotList() {
      let res = await buzz_list({page: 1})
      this.hotList = res.data.list
    }
  }
}
</script>


<style scoped lang="less">
.buzzBody {
  margin-top: 40px;
  display: flex;
  align-items: start;
  margin-bottom: 60px;
  align-items: stretch;

  .buzzLeftTop {
    flex: 1;

    .checkBtn {
      margin: 60px auto;
      text-align: center;

      span {
        font-size: 24px;
        line-height: 26px;
        padding: 5px 20px 7px;
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        cursor: pointer;
      }

      span:hover {
        background: #00a0e9;
        color: #ffffff;
        border-color: #00a0e9;
      }
    }
  }

  .buzzLeft {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 40px;

    .content {
      width: 45%;
      background: #ffffff;
      border-radius: 10px;

      .top {
        position: relative;
        cursor: pointer;

        img {
          border-radius: 10px 10px 0 0;
        }

        .firstP {
          display: inline-block;
          position: absolute;
          bottom: -20px;
          right: 0;
          color: #ffffff;
          font-size: 21px;
          line-height: 30px;
          background: #00A2FD;
          border-radius: 10px 0 0 10px;
          padding: 5px 20px;
        }
      }

      .buzzBqBody {
        padding: 40px;
        cursor: pointer;
      }

      .bq {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-size: 16px;
          color: #616161;
        }

      }

      h3 {
        color: #272727;
        font-size: 25px;
        line-height: 30px;
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .detail {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 16px;
        line-height: 20px;
        color: #666666;
        margin-bottom: 40px;
      }

      .el-button {
        border-radius: 25px;
        font-size: 18px;
        line-height: 20px;
        color: #0B57C9;
        padding: 10px 20px;
      }

      .el-button:hover {
        background: #00A2FD;
        color: #ffffff;
      }
    }

  }

  .buzzRight {
    width: 300px;
    position: relative;

    .buzzTitle {
      font-size: 36px;
      line-height: 44px;
      color: #ffffff;
      padding-bottom: 20px;
      border-bottom: 1px solid #ffffff;
      margin-bottom: 40px;
    }

    .rightBody {
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 40px;

      span {
        font-size: 30px;
        padding: 2px 10px 5px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        margin-right: 20px;
      }

      .wenzhang {
        cursor: pointer;

        p:first-child {
          margin: 0;
          font-size: 20px;
          line-height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        p:last-child {
          display: none;
        }
      }
    }

    .buzzTitleTwo {
      font-size: 36px;
      line-height: 44px;
      color: #ffffff;
      padding-bottom: 20px;
      border-bottom: 1px solid #ffffff;
      margin-bottom: 40px;
    }

    .typeBtn {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      div {
        margin-bottom: 15px;

        span {
          display: inline-block;
          color: #666666;
          width: 110px;
          padding: 10px 15px;
          font-size: 16px;
          border-radius: 25px;
          background: #EEEEEE;
          text-align: center;
          cursor: pointer;
        }

        span:hover, .active {
          background: #00A2FD;
          color: #ffffff;
        }

      }
    }
  }
}

@media screen and(max-width: 1024px) {
  .buzzBody {
    flex-wrap: wrap;

    .buzzLeft {
      .content {
        width: 47%;
      }
    }

    .buzzRight {
      width: 100%;

      .typeBtn {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
      }
    }
  }

  .ditu {
    display: none;
  }
}

@media screen and(max-width: 768px) {
  .buzzBody {
    flex-wrap: wrap;

    .buzzLeft {
      margin-bottom: 20px;

      .content {
        margin: 0 20px;
        width: calc(100vw - 40px);

        .top {
          img {
            height: 260px;
            object-fit: cover;
          }

          .firstP {
            bottom: 0;
            font-size: 16px;
            line-height: 20px;
            padding: 5px 15px;
          }
        }

        h3 {
          font-size: 18px;
          line-height: 22px;
        }

        .el-button {
          display: none;
        }

        .detail {
          margin-bottom: 20px;
        }
      }

      .buzzBqBody {
        padding: 20px !important;
        display: flex;
        flex-wrap: wrap;

        .bq {
          order: 3;
        }

        h3 {
          order: 1;
        }

        p {
          order: 2;
        }
      }
    }

    .buzzRight {
      margin: 0 20px;
      width: calc(100vw - 40px);

      .buzzTitle, .buzzTitleTwo {
        font-size: 24px;
        line-height: 30px;
        border-bottom: none;
        margin-bottom: 10px;
      }

      .rightBody {
        padding: 10px;
        background: #ffffff;
        border-radius: 10px;
        color: #272727;

        span {
          background: #EEEEEE;
          border: none;
          color: #666666;
          font-size: 24px;
          line-height: 29px;
          padding: 5px 12px;
        }

        .wenzhang {
          p:first-child {
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          p:last-child {
            display: block;
            margin: 0;
            font-size: 12px;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .typeBtn {
        gap: 10px;

        div {
          margin-bottom: 5px;

          span {
            width: 100px;
            padding: 10px;
          }
        }
      }
    }
  }
}
</style>
