<template>
  <div class="details">
    <div class="main-content">
      <div class="support-title contactus-title">
        FAQ無法解決您的疑惑嗎？ 歡迎聯絡我們....
      </div>
      <div class="support-body form-body">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm contact-form"
                 :inline="true">
          <div class="">
            <el-form-item prop="name" class="-col-4">
              <el-input v-model="ruleForm.name" placeholder="姓名" :validate-event="false"></el-input>
            </el-form-item>
            <el-form-item label="" prop="email" class="-col-4">
              <el-input v-model="ruleForm.email" placeholder="電子郵件" :validate-event="false"></el-input>
            </el-form-item>
            <el-form-item label="" prop="date" class="-col-4">
              <el-input type="text" placeholder="訂單編號（選填）" v-model="ruleForm.date"
                        style="width: 100%;"></el-input>
            </el-form-item>
          </div>
          <div class="">
            <el-form-item prop="subject" class="-col-8">
              <el-input v-model="ruleForm.subject" placeholder="主题" :validate-event="false"></el-input>
            </el-form-item>
            <el-form-item label="" prop="package" class="-col-4">
              <el-input v-model="ruleForm.package" placeholder="國家方案套餐（選填）"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="" prop="details" class="-col-12">
            <el-input type="textarea" v-model="ruleForm.details" placeholder="留下您的問題/寶貴意見"
                      :validate-event="false"></el-input>
          </el-form-item>
          <el-form-item class="el-form-submit -col-12">
            <div class="form-box-checkbox">
            <div id="grecaptcha" ></div>
            </div>
            <el-button class="form-submit-btn"  :class="isActive ? '':'disabled'" type="primary"
                       @click="submitForm('ruleForm')">發送
            </el-button>
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {touchUs} from '@/request/api'

export default {
  name: 'terms',
  data() {
    return {
      ruleForm: {
        name: '',
        email: '',
        date: '',
        subject: '',
        package: '',
        details: '',
        response_token: ''
      },
      rules: {
        name: [
          {required: true, message: '請輸入您的姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '請輸入您的電子郵件', trigger: 'blur'},
          { type: 'email', message: '請輸入正確的電子郵件格式', trigger: ['blur', 'change'] }
        ],
        subject: [
          {required: true, message: '請輸入您的主題', trigger: 'blur'}
        ],
        details: [
          {required: true, message: '請輸入您的請求詳情', trigger: 'blur'}
        ]
      },
      isChecked: false

    }
  },
  computed: {
    isActive() {
      let flag = false;
      flag = !this.ruleForm.name || !this.ruleForm.email || !this.ruleForm.subject || !this.ruleForm.details
      return flag
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded();
    })
  },

  methods: {
    loaded() {
        setTimeout(() => {
          window.grecaptcha.render("grecaptcha", {
            sitekey: '6Lcv0JQpAAAAALp7Gb-veKDz3Hmfc7WioHFuMxie',
            callback: this.submit
          });
        }, 200);
    },
  submit(token) {
      this.ruleForm.response_token = token
      this.isChecked = true
  },

  async submitForm(formName) {
    this.$refs[formName].validate((valid) => {
      if (valid) {
        if(!this.isChecked){
          return this.$message({
            message: '請確認不是機器人操作！',
            type: 'warning'
          })
        }
        this.send()
      }
    })
  },
   async send(){
      var that = this;
      let myheaders = {
        headers: {pc: true, "content-type": "multipart/form-data"},
      };
      let res = await touchUs(this.ruleForm, myheaders);
      if (res.code == 1) {
        this.$message({
          type: 'success',
          message: '發送成功!'
        })
        window.grecaptcha.reset()
        this.isChecked = false
        that.resetForm('ruleForm');
      } else {
        that.$message.error(res.msg);
      }
    },
  resetForm(formName) {
    this.$refs[formName].resetFields();
  }
}
}
</script>

<style lang="less" scoped>
::v-deep #grecaptcha div{
  margin: 10px auto !important;
}
.form-box-checkbox {
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
