import { render, staticRenderFns } from "./tutorial.vue?vue&type=template&id=f12fddc8&scoped=true"
import script from "./tutorial.vue?vue&type=script&lang=js"
export * from "./tutorial.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f12fddc8",
  null
  
)

export default component.exports