<template>
  <div class="details">
    <div class="main-content">
      <div class="terms-title">
        「服務條款」
      </div>
      <div class="terms-body">
        <p>同意並使用我們的eSIM產品，您將成為eSImart  eSIM服務的使用者。在這之前，請仔細閱讀本協議。使用此服務即視為接受本協議的所有條款。</p>

        <h5>1. 服務介紹</h5>
        <p>1.1 服務描述</p>
        <p>eSImart  eSIM是由eSImart .com提供的移動網絡服務產品（"我們"或"我們"）。該服務幫助出境旅客在指定地區保持連接，通過我們的應用程序和網站提供經濟實惠和方便的數據服務購買。</p>
        <p>1.2 服務覆蓋範圍</p>
        <div id="refundPolicy"></div>
        <p>我們的服務會隨著時間擴展至更多的國家和地區。</p>
        <h5>2. 退款政策</h5>
        <p>2.1 退款申請截止時間：在訂單確認郵件發送前提出申請。掃描的QR碼或安裝後恕不退款。</p>
        <p>2.2 申請流程：請將訂單編號碼、產品名稱、退款原因發送至service@eSImart .com。</p>
        <p>2.3 退款方式：大約在30個工作日內退回至原支付方式。</p>
        <h5>&nbsp;</h5>
        <p>在您旅途中若遇到任何與eSIM相關的問題，務必聯繫我們的支援團隊以獲得確認。我們將評估問題是否屬於我們的責任範圍，僅在確認的情況下才會啟動退款或交換流程。請再次強調，若在返回本國前未事先確認的情況下提出退款要求，將不予處理。</p>

        <h5>3. 付款</h5>
        <p>在可以訪問eSImart 網站的網路上，可以使用國際信用卡進行購買。</p>

        <h5>4. 異常</h5>
        <p>我們不對服務不可用負責，包括網絡問題、自然災害和設備故障等各種情況。</p>

        <h5>5. 免責聲明</h5>
        <p>使用服務時請負責任，以避免影響其他人的體驗，因為我們可能會限制速度或終止服務，以防止過度使用數據。</p>

        <h5>6. 隱私保護</h5>
        <p>同意我們的隱私政策；為了增強服務而進行的數據收集；已實施的數據安全措施。</p>

        <h5>7. 使用限制</h5>
        <p>不得更改、拆解或逆向工程该服务。</p>

        <h5>8. 更改條款</h5>
        <p>我們偶爾可能會修改這份協議；您繼續使用即表示接受變更</p>

        <h5>9. 違約條款</h5>
        <p>我們可能會因為服務交付問題而發出退款；退款金額不會超過支付金額。</p>

        <h5>10. 法律有效性</h5>
        <p>台灣中華民國的法律管轄；通過台灣中華民國國際仲裁中心解決爭議。</p>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    this.goRefundPolicy()
  },
  methods: {
   goRefundPolicy(){
     // 獲取URL中問號後面的參數
     // const params = new URLSearchParams(window.location.search);
     // const id = params.get('id');
     const { id } = this.$route.query
     if (id) {
       // 使用DOM操作滾動到對應位置
       const element = document.getElementById(id);
       if (element) {
         setTimeout(() => {
           element.scrollIntoView({});
         }, 100)
       }
     }
   }
  },
  watch:{
    '$route.query.id'(newId, oldId) {
      // 在這裡你可以進行相應的操作，比如滾動到對應位置
      const element = document.getElementById(newId);
      if (element) {
        this.goRefundPolicy()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.terms-title{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 32px;line-height: 46px;color: #FFFFFF;text-align: center;margin-bottom: 35px;}
.terms-body{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;color: #FFFFFF;}
.terms-body h5{color: #00A2FD;margin-top: 20px;}
.terms-body h6{}
@media screen and (max-width: 800px) {
  .terms-title{margin-bottom: 15px;}
  .details{padding-bottom: 40px;}
}
</style>
