<template>
  <div>
    <div class="closing main-content">
      <div class="crumbs">
        <ul>
          <li><a href="#" @click="$router.push('/')">首頁</a></li>
          <li>結帳</li>
        </ul>
      </div>
      <div class="main">
        <h2 class="cart-title">
          <div class="_ico"><img src="@/assets/image/cart/closing-title.png" alt="購物車"></div>
          <span>結帳</span>
        </h2>
        <div class="cart-box">
          <div class="row">
            <div class="col _left">
              <div class="order-box">
                <div class="_title">您的訂單</div>
                <!--              <div class="_info">訂單編號：{{ orderobj.order_number }}</div>-->
                <div class="_info-box">
                  <div class="_title">產品：</div>
                  <div class="_item" v-for="(item, i) in orderobj.plan_list" :key="i">
                    <div class="_txt">{{ item.name }}</div>
                    <div class="_box cus-box">
                      <div class="_txt">{{ item.days + ' Days' }} {{ item.spec }}<small
                          v-if="item.package && item.package.includes('By Day')">/ 天</small> x {{ item.num }}
                      </div>
                      <div class="_txt"> $NT {{ discount > 0 ? item.amount.toLocaleString() : item.special_amount.toLocaleString() }}</div>
                    </div>

                  </div>
                  <div class="_code">
                    <div class="_txt">折扣碼</div>
                    <el-input placeholder="折扣碼" v-model="orderobj.promo_code"
                              class="input-code">
                      <button slot="append" type="button" class="btn btn-default"
                              @click="getcode">使用
                      </button>
                    </el-input>
                  </div>
                  <p style="font-size: 14px;color: #5CE9C7;text-align: end;margin: 0">如果使用折扣碼是使用原價進行優惠結算</p>
                  <div class="_total">
                    <div class="_box">
                      <div class="_txt">小計</div>
                      <div class="_txt"> $NT
                        {{
                          orderobj.plan_list ? orderobj.plan_list.reduce((sum, obj) => {
                            return sum + (discount > 0 ? Number(obj.amount) * Number(obj.num) : Number(obj.special_amount) * Number(obj.num))
                          }, 0).toLocaleString() : ''
                        }}

                      </div>
                    </div>
                    <div class="_box" v-if="orderobj.delivery_fee">
                      <div class="_txt">運費</div>
                      <div class="_txt">$NT {{ orderobj.delivery_fee.toLocaleString() }}</div>
                    </div>
                    <div class="_box">
                      <div class="_txt">折扣</div>
                      <div class="_txt">- $NT {{ discount ? discount.toLocaleString() : discount }}</div>
                    </div>
                    <div class="_box" style="border-bottom: 1px solid #2E2E2E;padding-bottom: 10px;margin-bottom: 20px">
                      <div class="_txt">總計</div>
                      <div class="_txt total"> $NT <span class="_total">{{
                          Number(orderobj.discount_amountTwo).toLocaleString()
                        }}</span></div>
                    </div>
                    <!--                    <div class="_box">-->
                    <!--                      <div class="_txt">我的eSIM Coin</div>-->
                    <!--                      <div class="_txt">6000</div>-->
                    <!--                    </div>-->
                    <!--                    <div class="_code" style="padding-top: 0">-->
                    <!--                      <div class="_txt">eSIM Cion折抵</div>-->
                    <!--                      <el-input v-currency placeholder="" v-model="point"-->
                    <!--                                class="input-code" @change="setPoint">-->
                    <!--                      </el-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="_box">-->
                    <!--                      <div class="_txt">折抵金額</div>-->
                    <!--                      <div class="_txt">- $NT {{ point ? (point / 10).toLocaleString() : 0 }}</div>-->
                    <!--                    </div>-->
                    <!--                    <div class="_box">-->
                    <!--                      <div class="_txt">總計</div>-->
                    <!--                      <div class="_txt total"> $NT <span class="_total">{{-->
                    <!--                          Number(orderobj.discount_amountTwo).toLocaleString()-->
                    <!--                        }}</span></div>-->
                    <!--                    </div>-->
                    <div class="_message">
                      <el-input type="textarea" :rows="4" placeholder="訊息" v-model="orderobj.remark">
                      </el-input>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col _right">
              <div class="closing-box">

                <div class="fp-tabs">
                  <div class="_item _label" style="font-size: 16px">請填寫您的資訊</div>
                </div>
                <div class="btntable">
                  <span>電子收據</span>
                  <el-button :class="bill_type == 1?'active':''" @click="bill_type = 1">一般收據</el-button>
                  <el-button :class="bill_type == 2?'active':''" @click="bill_type = 2">報帳收據</el-button>
                  <img @click="dialogTableVisible = true" src="@/assets/image/wenhao.png" alt=""
                       style="cursor: pointer"/>
                </div>
                <el-form
                    ref="contactForm"
                    :model="form"
                    :rules="rules"
                >
                  <div class="fp-body">
                    <div class="fp-item">
                      <div class="_item">
                        <el-form-item prop="lastName">
                          <el-input v-model="form.lastName" placeholder="名字"></el-input>
                        </el-form-item>
                      </div>
                      <div class="_item">
                        <el-form-item prop="fristName">
                          <el-input v-model="form.fristName" placeholder="姓氏"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="fp-item" v-if="bill_type == 2">
                      <div class="_item">
                        <el-form-item prop="un_num">
                          <el-input v-model="form.un_num" placeholder="統一編號"></el-input>
                        </el-form-item>
                      </div>
                      <div class="_item">
                        <el-form-item prop="company_title">
                          <el-input v-model="form.company_title" placeholder="公司抬頭"></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div>
                      <span>收件資訊</span>
                      <span style="margin-bottom: 25px;display: inline-block;margin-left: 15px;font-size: 14px;
        line-height: 20px;color: #5CE9C7;">訂單將發送至以下地址</span>
                    </div>
                    <div class="fp-item">
                      <div class="_item" style="margin-bottom: 10px;flex-basis: 50%;flex-grow: 0;">
                        <el-form-item prop="email">
                          <el-input v-model="form.email" type="email"
                                    placeholder="你的電子郵件"></el-input>
                        </el-form-item>
                      </div>
                      <div class="_item" style="margin-bottom: 10px;">
                        <el-form-item prop="reEmail">
                          <el-input v-model="form.reEmail" type="email"
                                    placeholder="請重新輸入電子郵件"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="fp-item" v-if="delivery_fee > 0">
                      <div class="_item" style="margin-bottom: 10px;flex-basis: 50%;flex-grow: 0;">
                        <el-form-item prop="first_id">
                          <el-select v-model="form.first_id" placeholder="請選擇地區" @change="getcountryList"
                                     style="width: 100%">
                            <el-option v-for="(item,i) in areaList" :key="i" :value="item.id"
                                       :label="item.name"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="_item" style="margin-bottom: 10px;">
                        <el-form-item prop="second_id">
                          <el-select v-model="form.second_id" placeholder="請選擇鄉鎮" style="width: 100%">
                            <el-option v-for="(item,i) in countryList" :key="i" :value="item.id"
                                       :label="item.name"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>

                    <div class="fp-item" v-if="delivery_fee > 0">
                      <div class="_item" style="margin-bottom: 10px;flex-basis: 100%;flex-grow: 0;">
                        <el-form-item prop="address_detail">
                          <el-input v-model="form.address_detail  "
                                    placeholder="詳細地址"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                    <el-radio-group v-model="payRadio"
                                    style="display: flex;align-items: center;flex-wrap: wrap;gap: 20px;margin-bottom: 15px"
                                    @change="getApplePrtime">
                      <el-radio :label="1">
                        <div class="item-title">
                          <div class="_label">信用卡資訊</div>
                          <img src="../../assets/image/cart/card-type.png"/>
                        </div>
                      </el-radio>
                      <el-radio :label="2" v-if="isIos">
                        <div class="item-title">
                          <div class="_label">Apple Pay</div>
                          <img src="../../assets/image/cart/apple.png"/>
                        </div>
                      </el-radio>
                    </el-radio-group>


                    <div class="form-item" v-show="payRadio == 1">
                      <div class="tpfield" id="card-number"></div>
                      <el-input prefix-icon="ico-cart" class="icon-custom" disabled v-model="value"
                                placeholder=""></el-input>
                    </div>
                    <div class="row form-item col-2" v-show="payRadio == 1">
                      <div class="col _item">
                        <div class="_label">到期日</div>
                        <div class="btItem">
                          <div class="btItem-nox" id="card-expiration-date"></div>
                          <el-input v-model="value" disabled placeholder=""></el-input>
                        </div>
                      </div>
                      <div class="col _item">
                        <div class="_label">安全碼</div>
                        <div class="btItem">
                          <div class="btItem-nox" id="card-ccv"></div>
                          <el-input v-model="value" disabled placeholder=""></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
                <div class="fukuan">
                  <div class="form-item">
                    <div class="row" style="margin-bottom: 10px"
                         v-if="orderobj.have_esim_type == 1 || orderobj.have_esim_type == 3">
                      <div class="col form-item-txt checkStyle">
                        <el-checkbox v-model="checked"></el-checkbox>
                        <p>已確認有支援eSIM的手機設備，出國後如遇到安裝使用問題會與eSImart主動聯繫。</p>
                      </div>
                    </div>
                    <div class="row" v-if="orderobj.have_esim_type == 2 || orderobj.have_esim_type == 3">
                      <div class="col form-item-txt checkStyle">
                        <el-checkbox v-model="checkedTwo"></el-checkbox>
                        <p>實體卡訂單會於下個工作天統一掛號寄出郵寄時間3-5天，<span
                            style="color: red">急單請勿下單</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="form-item">
                    <div class="row ">
                      <div class="col form-item-btn">
                        <button type="button" class="btn btn-primary btn-block set-up" :disabled="btnActive"
                                @click="goTO">
                          確認付款
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-item">
                    <div class="row">
                      <div class="col form-item-txt text-center">
                        本公司採用喬睿科技 TapPay 金流交易系統，消費者刷卡時直接在銀行端系統中交易，本公司不會留下您的信用卡資料，以保障你的權益，資料傳輸過程採用嚴密的
                        SSL 2048bit 加密技術保護
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--      <div>-->
      <!--        <label>CardView</label>-->
      <!--        <div id="cardview-container"></div>-->
      <!--      </div>-->
      <!--    //測試用，正式上線需刪除-->
      <div class="ui grid centered doubling stackable" style="display: none;">
        <div class="six wide column">
          <div class="ui segment">
            <h1 class="ui header">直接支付 - iframe</h1>

            <form class="ui form">
              <div class="field">
                <label>信用卡</label>
                <!--                <div id="tappay-iframe"></div>-->
              </div>
            </form>

            <br>
            <div class="ui button" id="submit">Get Prime</div>
            <br>
            <pre class="ui error message" id="message" style="overflow-x: auto">
                </pre>
            <pre class="ui info message" id="result" style="overflow-x: auto">
                </pre>
            <pre class="ui info message" id="curl" style="overflow-x: auto">
                </pre>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="收據開立說明" center :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <p>自108年8月1日起，依財政部賦稅署台財稅
        第851923349號函暨台財稅881943611號函，開立「國際電話卡收據」，不另開發票。
        此收據為政府合法憑證，可填入公司抬頭及統一編號報帳使用。</p>
      <div class="_btnn">
        <el-button @click="dialogTableVisible = false">關閉</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import '../../utils/tappay.js'
import {
  codeCheck,
  getCurrentRate,
  select_address, placeOrder
} from "../../request/api";
import {country} from "./data";

export default {
  name: 'Closing',
  data() {
    return {
      isIos: true,
      dialogTableVisible: false,
      checkedTwo: false,
      payRadio: 1,
      num: 1,
      value: '',
      code: 'TX8767',
      phoneNumber: '',
      btnActive: true,
      countryImg: '-5241px 0',
      height: '11px',
      countryIndex: 1,
      country: country,
      receipt: 1,
      question: false,
      codeDisabled: false,// 優惠碼禁止點擊
      point: null,
      options: [
        {
          value: 1,
          label: '選項1'
        },
        {
          value: 2,
          label: '選項2'
        }
      ],
      tagList: ['# 美國', '# 本地卡', '# 按日計價'],

      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '使用方法'
        },
      ],
      orderobj: {},
      discount: 0,

      isshow: false,
      iniTP: {},
      prime: '',
      phone_number: '',
      rate: 0,// 匯率
      type: 1,
      checked: false,
      bill_type: 1,


      form: {
        un_num: '',
        company_title: '',
        fristName: '',
        email: '',
        lastName: '',
        reEmail: '',
        first_id: null,
        second_id: null,
        address_detail: null
      },
      areaList: [],
      countryList: [],
      delivery_fee: 0,
      rules: {
        // problem_class_id: { required: true, message: 'Class is required', trigger: 'blur' },
        fristName: {required: true, message: '姓氏是必填項', trigger: 'blur'},
        lastName: {required: true, message: '名字是必填項', trigger: 'blur'},
        un_num: {required: true, message: '統一編號是必填項', trigger: 'blur'},
        company_title: {required: true, message: '公司抬頭是必填項', trigger: 'blur'},
        email: [
          {required: true, message: '電子郵件是必填項', trigger: 'blur'},
          {type: 'email', message: '請輸入正確的電子郵件地址', trigger: 'blur'}
        ],
        reEmail: [
          {required: true, message: '電子郵件是必填項', trigger: 'blur'},
          {validator: this.validateEmailConfirm, trigger: 'blur'},
        ],
        first_id: {required: true, message: '請選擇地區', trigger: 'change'},
        second_id: {required: true, message: '請選擇鄉鎮', trigger: 'change'},
        address_detail: {required: true, message: '請填寫詳細地址', trigger: 'blur'}
      },
      displayItems: []
    }
  },
  created() {
    this.getCurrentRate()
    this.getOrderDetails()
    // this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && navigator.userAgent.indexOf("Safari") > -1
    this.isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent)

  },

  mounted() {
    this.getApplePrtime()

    Array.from(document.getElementsByClassName("el-select")).forEach(item => {
      item.children[0].children[0].removeAttribute("readOnly");
      item.children[0].children[0].onblur = function () {
        let _this = this;
        setTimeout(() => {
          _this.removeAttribute("readOnly");
        }, 200);
      };
    });
    // 信用卡
    TPDirect.setupSDK(132722, 'app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ', 'sandbox');
    // TPDirect.setupSDK(132722, 'app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ', 'production');
    //測試用，正式上線需刪除
    var statusTable = {
      '0': '欄位已填好，並且沒有問題',
      '1': '欄位還沒有填寫',
      '2': '欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor',
      '3': '使用者正在輸入中',
    }
    let _this = this
    let fields = {
      number: {
        // css selector
        // element: '#card-number',
        element: document.getElementById('card-number'),
        placeholder: '請輸入卡號'
      },
      expirationDate: {
        // DOM object
        element: document.getElementById('card-expiration-date'),
        placeholder: 'MM/YY'
      },
      ccv: {
        element: '#card-ccv',
        placeholder: 'ccv'
      }
    }
    var config = {
      isUsedCcv: false,
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    }
    // TPDirect.card.setup(fields, defaultCardViewStyle, config)

    TPDirect.card.setup({
      fields: fields,
      styles: {
        // Style all elements
        'input': {
          'color': '#ffffff'
        },
        // Styling ccv field
        'input.ccv': {
          // 'font-size': '16px'
        },
        // Styling expiration-date field
        'input.expiration-date': {
          // 'font-size': '16px'
        },
        // Styling card-number field
        'input.card-number': {
          // 'font-size': '16px'
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          'color': 'green'
        },
        // style invalid state
        '.invalid': {
          'color': 'red'
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          'input': {
            'color': 'orange'
          }
        }
      },
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    })
    TPDirect.card.onUpdate(function (update) {
      //測試用，正式上線需刪除
      message.innerHTML = `
                canGetPrime: ${update.canGetPrime} \n
                cardNumberStatus: ${statusTable[update.status.number]} \n
                cardExpiryStatus: ${statusTable[update.status.expiry]} \n
                ccvStatus: ${statusTable[update.status.ccv]}
            `.replace(/    /g, '')

      if (update.hasError) {
        console.log('error', update)
        _this.btnActive = true
      } else {
        console.log('info', update)
        _this.btnActive = false
      }
    })


  },

  methods: {
    getApplePrtime() {
      if (this.payRadio == 2) {
        this.btnActive = false
      } else {
        this.btnActive = true
      }
    },

    // 監聽輸入點數
    setPoint() {
      if (!this.point % 10 == 0) {
        this.point = 0
        this.$message.warning('請輸入10的倍數！')
      }
    },

    getcountryList() {
      this.form.second_id = null
      this.countryList = this.areaList.find(item => item.id == this.form.first_id).children
    },


    getaddressList() {
      select_address().then(res => {
        this.areaList = res.data
      })
    },
    validateEmailConfirm(rule, value, callback) {
      if (value !== this.form.email) {
        callback(new Error('兩次電子郵件不一樣'));
      } else {
        callback();
      }
    },


    send_to_page() {
      // console.log(this.orderobj.plan_list)
      let items = this.orderobj.plan_list.map(v => {
        return {
          item_id: v.id,
          item_name: v.name,
          item_category: v.days + '天',
          item_category2: v.spec,
          item_category3: v.package,
          quantity: v.num,
          price: v.special_amount
        }
      })
      let event_config = {
        currency: "TWD",
        value: Number(this.orderobj.discount_amountTwo),
        items: items
      }
      this.$gtag.event('begin_to_check_out', event_config);
    },
    showSelectedImage(id) {
      const img = this.country.filter(v => {
        if (v.value === id) {
          return true
        }

      })
      this.countryImg = img[0].pos
      this.height = img[0].height
      this.phone_number = '+' + img[0].code
    },
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num += 1
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },
    //去付款
    async goTO() {
      this.$refs.contactForm.validate(async (valid) => {
        if (valid) {
          if ((this.orderobj.have_esim_type == 1 && this.checked) || (this.orderobj.have_esim_type == 2 && this.checkedTwo) || (this.orderobj.have_esim_type == 3 && this.checked && this.checkedTwo)) {
            this.getPrim()
          } else {
            this.$message.warning('請勾選')
          }
        }
      })


      // this.getPrim()

    },
    // 新的付款
    async getPrim() {
      let _this = this
      if (_this.payRadio == 2) {
        this.displayItems = []
        this.orderobj.plan_list.forEach((item, i) => {
          const obj = {
            label: item.name,
            amount: {
              currency: 'TWD',
              value: i == 0 && this.orderobj.have_esim_type != 1 ? (this.discount > 0?Number(item.amount):Number(item.special_amount)) * Number(item.num) + Number(this.orderobj.delivery_fee) : (this.discount > 0?Number(item.amount):Number(item.special_amount)) * Number(item.num)
            }
          }
          this.displayItems.push(obj)
        })
        let price = Number(this.discount)
        if (this.displayItems.length == 1) {
          if (Number(this.displayItems[0].amount.value) - price < 0) {
            this.displayItems[0].amount.value = 0
          } else {
            this.displayItems[0].amount.value = Number(this.displayItems[0].amount.value) - price
          }
        } else {
          let newPrice = price
          for (let i = 0; i < this.displayItems.length; i++) {
            if (this.displayItems[i].amount.value > newPrice) {
              this.displayItems[i].amount.value = Number(this.displayItems[i].amount.value) - newPrice
              break
            } else {
              newPrice = newPrice - Number(this.displayItems[i].amount.value)
              this.displayItems[i].amount.value = 0
            }
          }
        }

        var datas = {
          supportedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
          supportedMethods: ['apple_pay'],

          displayItems: this.displayItems,
          total: {
            label: 'TapPay',
            amount: {
              currency: 'TWD',
              value: this.orderobj.discount_amountTwo
            }
          },
          shippingOptions: [{
            id: "standard",
            label: "郵寄",
            amount: {
              currency: "TWD",
              value: this.orderobj.delivery_fee
            }
          }
          ],
          options: {
            requestPayerEmail: false,
            requestPayerName: false,
            requestPayerPhone: false,
            requestShipping: false,
            // shippingType: 'shipping'
          }
        }

        // TPDirect.setupSDK("132722", "app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ", 'sandbox')
        TPDirect.paymentRequestApi.setupApplePay({
          merchantIdentifier: 'APMEIorj4bjXe9vEZgW8',
          countryCode: 'TW',
        })
        TPDirect.paymentRequestApi.setupPaymentRequest(datas, function (result) {
          console.log(result)
        })



        TPDirect.paymentRequestApi.getPrime((result) => {
          console.log('paymentRequestApi.getPrime result', result)
          if (result.status !== 0) {
            console.error('getPrime failed: ' + result.msg)
            return
          }
          var prime = result.prime
          _this.prime = prime
          _this.goToPay()
        })
      } else {
        TPDirect.card.getPrime(function (result) {
          console.log('result', result)
          if (result.status !== 0) {
            console.error('getPrime error')
          }
          var prime = result.card.prime
          _this.prime = prime
          _this.goToPay()

        })
      }
    },
    async goToPay() {
      if (this.prime != '') {
        const plan_list = []
        this.orderobj.plan_list.forEach(item => {
          let obj = {
            id: item.esimart_plan_id,
            price_id: item.esimart_price_id,
            num: item.num
          }
          plan_list.push(obj)
        })
        let data = {
          plan: plan_list,
          order_id: this.$route.query.id,
          prime: this.prime,
          name: this.form.fristName + this.form.lastName,
          email: this.form.email,
          bill_type: this.bill_type,
          un_num: this.bill_type == 1 ? '' : this.form.un_num,
          company_title: this.bill_type == 1 ? '' : this.form.company_title,
          first_id: this.form.first_id,
          second_id: this.form.second_id,
          address_detail: this.form.address_detail,
          step: 2,
          pay_type: this.payRadio,
          promo_code: this.discount > 0 ? this.orderobj.promo_code : ''
        }
        let res = await placeOrder(data);
        if (res.code == 200) {
          let items = this.orderobj.plan_list.map(v => {
            return {
              item_id: v.id,
              item_name: v.name,
              item_category: v.days + 'Days',
              item_category2: v.spec,
              item_category3: v.package,
              quantity: v.num,
              price: v.special_amount,
            }
          })
          let event_config = {
            currency: "TWD",
            coupon: this.orderobj.promo_code,
            value: Number(this.orderobj.discount_amountTwo),
            items: items
          }
          window.sessionStorage.removeItem('shopCart')
          this.$store.commit('changgetCart')
          this.$gtag.event('confirm_Payment', event_config);
          window.location.href = res.data.payment_url
        } else {
          this.$message.warning(res.msg)
        }
      }
    },


    //獲取訂單詳情
    async getOrderDetails() {
      let data = JSON.parse(this.$route.query.data)
      let res = await placeOrder(data);
      if (res.code == 1) {
        this.orderobj = res.data;
        this.orderobj.discount_amountTwo = res.data.discount_amount;
        // this.displayItems = []
        // this.orderobj.plan_list.forEach((item,i) => {
        //   const obj = {
        //     label: item.name,
        //     amount: {
        //       currency: 'TWD',
        //       value: i == 0 && this.orderobj.have_esim_type != 1? Number( item.is_discount == 1?item.special_amount:item.amount) * Number(item.num) + Number(this.orderobj.delivery_fee):Number(item.is_discount == 1?item.special_amount:item.amount)* Number(item.num)
        //     }
        //   }
        //   this.displayItems.push(obj)
        // })
        if (res.data.promo_code) {
          this.getcode()
        }
        if (window.localStorage.getItem('TOKEN_NAME')) {
          this.isshow = true
        } else {
          this.isshow = false
        }
        // this.fristName = res.data.user_name
        this.form.email = res.data.user_email
        this.form.phone = res.data.user_phone,
            this.form.reEmail = res.data.user_email
        this.send_to_page();
        if (res.data.delivery_fee > 0) {
          this.getaddressList()
          this.delivery_fee = res.data.delivery_fee
        }
        // this.$nextTick(() => {
        //   var datas = {
        //     supportedNetworks: ['AMEX', 'JCB', 'MASTERCARD', 'VISA'],
        //     supportedMethods: ['apple_pay'],
        //
        //     displayItems: this.displayItems,
        //     total: {
        //       label: 'TapPay',
        //       amount: {
        //         currency: 'TWD',
        //         value: this.orderobj.discount_amountTwo
        //       }
        //     },
        //     shippingOptions: [{
        //       id: "standard",
        //       label: "郵寄",
        //       amount: {
        //         currency: "TWD",
        //         value: this.orderobj.delivery_fee
        //       }
        //     }
        //     ],
        //     options: {
        //       requestPayerEmail: false,
        //       requestPayerName: false,
        //       requestPayerPhone: false,
        //       requestShipping: false,
        //       // shippingType: 'shipping'
        //     }
        //   }
        //
        //   // TPDirect.setupSDK("132722", "app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ", 'sandbox')
        //   TPDirect.paymentRequestApi.setupApplePay({
        //     merchantIdentifier: 'APMEIorj4bjXe9vEZgW8',
        //     countryCode: 'TW',
        //   })
        //   TPDirect.paymentRequestApi.setupPaymentRequest(datas, function (result) {
        //     console.log(result)
        //   })
        // })


      } else {
        this.$message.warning(res.msg)
      }
    },

    async getcode() {
      if (this.orderobj.promo_code) {
        let data = {
          promo_code: this.orderobj.promo_code
        }
        let res = await codeCheck(data)
        if (res.code == 1) {
          const totalPrice = this.orderobj.plan_list.reduce((sum, obj) => {
            return sum + Number(obj.amount) * Number(obj.num)
          }, 0)
          this.discount = Math.round(Number(totalPrice) * (Number(res.data.discount) / 100))
          this.orderobj.discount_amountTwo = (Math.round(Number(totalPrice) - Number(this.discount))) > 0 ? Math.round(Number(totalPrice) - Number(this.discount)) + this.orderobj.delivery_fee : 0;
          this.codeDisabled = true
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
          this.orderobj.discount_amountTwo = this.orderobj.plan_list.reduce((sum, obj) => {
            return sum + Number(obj.special_amount) * Number(obj.num)
          }, 0) + this.orderobj.delivery_fee;
          this.discount = 0
        }
      } else {
        this.discount = 0;
        this.orderobj.discount_amountTwo = this.orderobj.plan_list.reduce((sum, obj) => {
          return sum + Number(obj.special_amount) * Number(obj.num)
        }, 0) + this.orderobj.delivery_fee;
      }
    },

    async getCurrentRate() {
      const res = await getCurrentRate({})
      this.rate = Number(res.data.rate)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  width: 456px;
  border-radius: 10px;

  p {
    font-size: 18px;
    color: #000000;
    line-height: 26px;
  }

  ._btnn {
    margin-top: 30px;
    text-align: center;

    .el-button {
      color: #ffffff;
      padding: 7px 100px;
      border-radius: 20px;
      background: #130F26;
    }
  }

}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 30px 48px 15px;
}

::v-deep .el-dialog__header {
  background: #2E2E2E;
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
}

::v-deep .el-dialog__title {
  color: #ffffff;
  font-size: 22px;
  line-height: 22px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
  font-size: 22px;
}

::v-deep .el-dialog__headerbtn {
  top: 10px;
}

.item-title {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.el-radio {
  display: flex;
  align-items: center;
}


.cart-title {
  display: flex;
  align-items: center;
  gap: 20px;
}


.closing .cart-box ._left {
  max-width: 500px;
}

.closing-box {
  width: 100%;
}

._code {
  display: flex;
  align-items: center;

  ._txt {
    flex: 1;
  }

  .input-code {
    flex: 1;
  }
}

.closing-box {
  position: relative;
  height: 550px;
}

.fukuan {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

._message {
  display: flex;
  white-space: nowrap;
}

.el-textarea {
  // margin-left: 10px;
}

::v-deep .el-textarea .el-textarea__inner {
  background: transparent;
  border-color: transparent;
  padding: 0;
}

.tip {
  color: #5CE9C7;
  font-size: 12px;
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.tip p {
  color: #5CE9C7;
}

.tip a {
  color: #00A2FD;
}

@media screen and (max-width: 800px) {
  .cart-title {
    justify-content: center;
  }


  .closing .order-box ._info-box ._code {
    flex-flow: column;
    align-items: start;
  }

  .closing .order-box ._info-box ._box.cus-box {
    flex-wrap: wrap;
  }

  .closing .order-box ._info-box ._box.cus-box ._txt:first-child {
    width: auto;
  }

  ::v-deep .input-code .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
}

.btntable {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  span {
    white-space: nowrap;
  }

  .el-button {
    background: #2E2E2E;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 27px;
  }

  .active {
    background: #0B57C9;
  }

}

.closing-box .item-title ._label {
  min-width: 50px;
  margin-bottom: 0;
}


::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ffffff;
}

.checkStyle {
  display: flex;
  text-align: start;
  align-items: stretch;

  p {
    font-size: 14px;
    margin: 1px 0 0 10px;
  }
}

@media screen and(max-width: 528px) {
  .el-radio:first-child {
    order: 1;
  }

  .el-radio:last-child {
    order: 0;
  }

  ::v-deep .el-dialog {
    width: 350px;
  }
}

@media (min-width: 801px) and(max-width: 1280px) {
  .el-radio:first-child {
    order: 1;
  }

  .el-radio:last-child {
    order: 0;
  }
}
</style>
