<template>
  <div class="details">
    <h2 class="details-h2">
      <span @click="$router.push('/')">eSimart</span>
      <!-- / {{ Prouductdata.area_name }} / {{ Prouductdata.country_name }}  -->
      / {{
        Prouductdata.name
      }}
    </h2>
    <div class="details-content">
      <div class="content-left">
        <!-- <img src="@/assets/image/product/details1.png" alt=""> -->
        <img :src="Prouductdata.image" :alt="Prouductdata.name">
      </div>
      <div class="content-right">
        <h1 class="content-right-h1">{{ Prouductdata.name }}</h1>
        <h2 class="content-right-h2">{{ Prouductdata.sketch }}</h2>
        <!--        <div class="content-right-select">-->
        <!--          <span>Specifications</span>-->
        <!--          <el-select v-model="value" placeholder="Please choose" no-data-text="Countless data">-->
        <!--            <el-option v-for="item in Prouductdata.price_list" :key="item.id" :label="item.spec" :value="item.id">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </div>-->
        <div class="content-right-select">
          <span>方案規格</span>
          <el-select v-model="value" placeholder="請選擇" no-data-text="無效的數據" @change="activeProduct = null">
            <el-option v-for="item in Prouductdata.days_list" :key="item" :label="item + ' 天'" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="price-box">
          <template v-for="item in priceList">
            <div v-if="item.package" :class="{ active: activeProduct === item.id }" @click="activeProduct = item.id"
                 :key="item.id" class="item">
              <div class="itemBox">
                <h3 style="margin: 0"><small>{{ packageHandle(item, 1) }}</small> {{ item.spec }} </h3>
                <p style="margin: 0">{{ packageHandle(item, 2) }}</p>
              </div>
            </div>
          </template>
        </div>

        <div class="content-right-price">
          <div class="oldPrice">
            原價
            <span>${{
                activePrice.amount ? activePrice.amount.toLocaleString() : activePrice.amount || '0'
              }}</span>
          </div>
          <div class="newPrice">
            特價
            <p> <span>$NT{{
                activePrice.special_amount ? activePrice.special_amount.toLocaleString() : activePrice.special_amount || '0'
              }}</span></p>
          </div>
        </div>

        <div class="content-right-num">
          <div class="counter">
            <span @click="less">-</span>
            <div>{{ num }}</div>
            <span @click="add">+</span>
          </div>
          <div class="loves" @click="toLoves" v-if="false">
            <img src="@/assets/image/icon/icon-loves.png" alt="">
          </div>
          <div class="share" @click="toShare" v-if="false">
            <img src="@/assets/image/icon/icon-share.png" alt="">
          </div>
        </div>
        <div class="content-right-button">
          <div class="button1" @click="toCart(1)">加入購物車</div>
          <div class="button2" @click="toCart(2)">立即購買</div>
        </div>
        <div class="content-right-share" v-if="false">
          <div @click="toLoves" v-if="false">
            <img src="@/assets/image/icon/icon-loves.png" alt="">
            加入我的收藏
          </div>
          <div @click="toShare">
            <img src="@/assets/image/icon/icon-share.png" alt="">
            分享
          </div>
        </div>
      </div>
    </div>
    <div class="details-tag">
      <span @click="$router.push({
        path: '/search',
        query: {
          content: item.title
        }
      })" v-for="(item, index) in Prouductdata.tag_list" :key="index">{{ '# ' + item.title }}</span>
    </div>
    <div class="details-tab">
      <div :class="tabActive == item.type ? 'tab-div tab-active' : 'tab-div'" v-for="(item, index) in tabList"
           :key="index" @click="tabChange(item.type)">
        {{ item.title }}
        <span></span>
      </div>
    </div>
    <div class="details-intro"
         v-if="tabActive != 3"
         v-html="tabActive == 1 ? Prouductdata.detail_text : tabActive == 2 ? Prouductdata.spec_text : Prouductdata.use_text">
    </div>
    <div class="details-intro pc"
         v-if="tabActive != 3"
         v-html="tabActive == 1 ? Prouductdata.detail_text_mobile : tabActive == 2 ? Prouductdata.spec_text_mobile : Prouductdata.use_text">
    </div>

    <!--評論-->
    <div class="pinglun" v-if="tabActive == 3">
      <div class="pLeft">
        <div class="pTop">
          <div class="avgPjLeft">
            <div><span>{{ totalRag }} / </span>
              <span>5.0</span>
            </div>
            <div>
              <!--              <el-rate v-model="totalRag" :max="5" :colors="colors" disabled></el-rate>-->
              <p>顧客評論( {{ totalPop }} )</p>
            </div>
          </div>
          <div class="avgPjRight">
            <el-button type="primary" @click="dialogTableVisible = true"><img src="@/assets/image/product/edit.png"/>撰寫評論
            </el-button>
          </div>
        </div>
        <div class="pBody" v-for="(item,i) in windowWidth>768? evaluateList:NewEvaluateList" :key="i">
          <div class="bodyTop">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="rate">
              <el-rate v-model="item.score" :max="5" :colors="colors" disabled></el-rate>
              <p>{{ item.addTime.substr(0, 4) }}年{{ item.addTime.substr(5, 2) }}月{{ item.addTime.substr(8, 2) }}日</p>
            </div>
          </div>
          <div class="bodyRight">
            <h3>{{ item.title }}</h3>
            <p>
              {{ item.txt }}</p>
          </div>
        </div>
        <el-pagination
            v-if="total"
            style="margin-top: 40px;text-align: center"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="form.page"
            :page-size="form.limit"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
        <div class="more" v-if="total != this.NewEvaluateList.length">
          <el-button @click="setpage">查看更多</el-button>
        </div>
      </div>
      <div class="pRight">
        <div class="paixu">
          排序：
          <el-select v-model="form.sort_by" placeholder="請選擇" @change="getEvaluateList">
            <el-option v-for="(item,i) in paixuList" :key="i" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </div>
        <div class="paiBtn">
          <el-button v-for="(item,i) in btnList" :key="i" :class="item.id == form.score?'active':''"
                     @click="setScore(item.id)">
            {{ item.name }}
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog title="請為本次產品評個分吧！" :visible.sync="dialogTableVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="score">
          <div class="dialogTop">
            <img :src="Prouductdata.image" alt=""/>
            <div>
              <p>{{ Prouductdata.name }}</p>
              <el-rate v-model="ruleForm.score" :max="5"></el-rate>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="" prop="name">
          <p>您的姓名</p>
          <el-input v-model="ruleForm.name" placeholder="名字或暱稱 （必填）"></el-input>
        </el-form-item>
        <el-form-item label="" prop="mail">
          <p>您的信箱</p>
          <el-input v-model="ruleForm.mail" placeholder="請輸入您的信箱（必填）"></el-input>
        </el-form-item>
        <el-form-item label="" prop="title">
          <p>評論標題</p>
          <el-input v-model="ruleForm.title" placeholder="請輸入標題（必填）"></el-input>
        </el-form-item>
        <el-form-item label="" prop="txt">
          <p>告訴我們您的想法</p>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="ruleForm.txt"
                    placeholder="您覺得本次體驗如何呢？請告訴我們 （選填）"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align: center;margin-top: 20px">
            <el-button @click="submitForm('ruleForm')">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-backtop :right="100">
      <div
          style="{
        height: 100%;
        width: 100%;
        background-color: #666666;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
         border-radius: 5px;
         color: #ffffff;
      }"
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import {
  getProductDetails,
  getCart,
  getCurrentRate,
  get_evaluate,
  submit_evaluate,
  score_group_list
} from '@/request/api'
import {getGroupByDays} from "../../request/api";

export default {
  name: 'Details',
  data() {
    return {
      form: {
        page: 1,
        plan_id: null,
        sort_by: 1,
        score: 0,
        limit: 5
      },
      ruleForm: {
        score: null,
        name: '',
        mail: '',
        title: '',
        txt: ''
      },
      rules: {
        score: [
          {required: true, message: '請點擊您的評分', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '請輸入您的名字', trigger: 'blur'}
        ],
        mail: [
          {required: true, message: '請輸入您的信箱', trigger: 'blur'},
          {type: 'email', message: '請輸入正確的信箱地址', trigger: ['blur', 'change']}
        ],
        title: [
          {required: true, message: '請輸入您的評論標題', trigger: 'blur'}
        ],
      },
      dialogTableVisible: false,
      btnIndex: 0,
      paixuList: [
        {id: 1, name: '最新評分'},
        {id: 2, name: '最舊評分'},
        {id: 3, name: '最高評分'},
        {id: 4, name: '最低評分'},
      ],
      btnList: [
        {id: 0, name: '全部'},
        {id: 5, name: '5.0'},
        {id: 4, name: '4.0'},
        {id: 3, name: '3.0'},
        {id: 2, name: '2.0'},
        {id: 1, name: '1.0'},
      ],
      totalPop: 0,
      value1: 4,
      num: 1,
      value: '',
      options: [],
      tagList: [],
      Prouductdata: {
        name: 'eSimart'
      },
      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '顧客評論'
        },
      ],
      activeProduct: null,
      rate: 0,// 匯率
      colors: ['#00A2FD', '#00A2FD', '#00A2FD'],
      evaluateList: [],
      total: 0,
      totalRag: 0,
      NewEvaluateList: [],
      windowWidth: window.innerWidth
    }
  },
  metaInfo() {
    return {
      title: `${this.Prouductdata.name} - Global eSIMs for Sale at eSImart.com`,
      meta: [
        {name: 'Title', content: `${this.Prouductdata.name} - Buy it at eSIMmart`, vmid: 'Title'},
        {name: 'Author', content: 'eSIMart', vmid: 'Author'},
        {name: 'Description', content: `${this.Prouductdata.seo_decription}-meta description`, vmid: 'Description'},
        {name: 'Keywords', content: `${this.Prouductdata.seo_keyword}-meta keywords`, vmid: 'Keywords'},
        {name: 'Language', content: 'English', vmid: 'Language'},
        {name: 'og:type', content: 'article', vmid: 'og:type'},
        {name: 'og:image', content: this.Prouductdata.seo_image, vmid: 'og:image'},
        {name: 'og:site_name', content: this.Prouductdata.seo_sitename, vmid: 'og:site_name'},
        {
          name: 'og:description',
          content: `${this.Prouductdata.seo_decription}-meta description`,
          vmid: 'og:description'
        },
        {name: 'og:url', content: window.location.href, vmid: 'og:url'},
        {
          name: 'og:title',
          content: `${this.Prouductdata.name} - Global eSIMs for Sale at eSImart.com`,
          vmid: 'og:title'
        }
      ]
    }
  },
  computed: {
    priceList() {
      if (this.value) {
        return this.Prouductdata.price_list[this.value]
      }
    },
    activePrice() {
      if (this.activeProduct) {
        return this.priceList.find(v => v.id === this.activeProduct)
      }
      return {}
    }
  },
  created() {
    // this.getPrcoduct();
    this.getGroupByDays()
    this.getCurrentRate()

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);

  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.scoreGroupList()
    this.getEvaluateList()
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setpage() {
      if (this.total != this.NewEvaluateList.length) {
        this.form.page += 1
        this.getEvaluateList()
        this.scoreGroupList()
      }
    },

    setScore(item) {
      this.form.score = item
      this.form.page = 1
      this.getEvaluateList()
    },

    handleSizeChange(val) {
      this.form.limit = val
      this.form.page = 1
      this.getEvaluateList()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getEvaluateList()
    },

    async scoreGroupList() {
      let res = await score_group_list({plan_id: this.$route.query.id})
      this.totalPop = 0
      this.totalRag = 0
      let maxFen = 0
      let minFen = 0
      res.data.group_list.map(item => {
        this.btnList.find(it => it.id == item.score).name = this.btnList.find(it => it.id == item.score).name.substring(0, 3) + ` ( ${item.count_num} )`
        this.totalPop += Number(item.count_num)
        maxFen += 5 * Number(item.count_num)
        minFen += Number(item.score) * Number(item.count_num)
        return item
      })
      if ((minFen / maxFen) * 5 > 0) {
        this.totalRag = ((minFen / maxFen) * 5).toPrecision(2)
      } else {
        this.totalRag = 0
      }

    },

    async getEvaluateList() {
      this.form.plan_id = this.$route.query.id
      let res = await get_evaluate(this.form)
      this.evaluateList = res.data.list
      this.total = res.data.total
      if (this.form.page == 1) {
        this.NewEvaluateList = res.data.list
      } else {
        this.NewEvaluateList = [...this.NewEvaluateList, ...res.data.list]
      }
    },


    // 提交評論
    submitForm(formName) {
      this.ruleForm.plan_id = this.$route.query.id
      if (this.ruleForm.score == 0) {
        this.$message.warning('請點擊您的評分')
        return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await submit_evaluate(this.ruleForm)
          if (res.code == 200) {
            this.dialogTableVisible = false
            this.ruleForm = {
              score: null,
              name: '',
              mail: '',
              title: '',
              txt: ''
            }, this.scoreGroupList()
            this.getEvaluateList()
          } else {
            this.$message.warning(res.msg)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    packageHandle(item, type) {
      const list = item.package.map(it => {
        if (it == '按天') {
          it = '每天'
        } else if (it == '固定') {
          it = '總計'
        } else if (it == '降速') {
          it = '到量降速'
        }
        return it
      })
      return type == 1 ? list[0] : list.filter((item, i) => i != 0).join('')
    },
    send_to_page() {
      let event_config = {
        items: [
          {
            item_id: this.$route.query.id,
            item_name: this.Prouductdata.name,
          }
        ]
      }
      console.log(event_config)
      this.$gtag.event('view_item', event_config);
    },
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num = Number(this.num) + Number(1);
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    //去購物車
    async toCart(i) {

      if (i == 1) {
        if (this.activeProduct && this.num > 0) {
          let event_config = {
            currency: "TWD",
            value: (this.num * this.activePrice.special_amount).toFixed(1),
            items: [
              {
                item_id: this.$route.query.id,
                item_name: this.Prouductdata.name,
                price: this.activePrice.special_amount,
                quantity: this.num
              }
            ]
          }
          this.$gtag.event('add_to_cart', event_config);
          if (window.localStorage.getItem('TOKEN_NAME')) {
            let data = {
              plan_id: this.$route.query.id,
              price_id: this.activeProduct,
              num: this.num
            }
            let res = await getCart(data);
            // this.$router.push({
            //   path: '/cart',
            //   query: {}
            // })
            this.$store.commit('changgetCart')
            this.$message.success('成功加入購物車')
          } else {
            let shopCart;
            if (window.sessionStorage.getItem('shopCart')) {
              shopCart = JSON.parse(window.sessionStorage.getItem('shopCart'))
            } else {
              shopCart = []
            }
            let data = {
              name: this.Prouductdata.name,
              sketch: this.Prouductdata.sketch,
              special_amount: this.activePrice.special_amount,
              num: this.num,
              image: this.Prouductdata.image,
              plan_id: this.Prouductdata.id,
              price_id: this.activeProduct,
              spec: this.activePrice.spec,
              days: this.value,
              package: this.activePrice.package
            }
            // console.log('data',data)
            if (shopCart.findIndex(iten => iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct) != -1) {
              shopCart[shopCart.findIndex(iten => iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct)].num = Number(shopCart[shopCart.findIndex(iten => iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct)].num) + Number(this.num)
            } else {
              shopCart.push(data);
            }
            window.sessionStorage.setItem('shopCart', JSON.stringify(shopCart))
            // this.$router.push({
            //   path: '/cart',
            //   query: {}
            // })
            this.$store.commit('changgetCart')
            this.$message.success('成功加入購物車')
          }

        } else if (!this.activeProduct) {
          this.$message({
            type: 'warning',
            message: '請選擇方案規格'
          })
        } else if (this.num < 1) {
          this.$message({
            type: 'warning',
            message: '請選擇數量'
          })
        }
      } else if (i == 2) {
        let list = []
        if (this.activeProduct && this.num > 0) {
          let event_config = {
            currency: "TWD",
            value: (this.num * this.activePrice.special_amount).toFixed(1),
            items: [
              {
                item_id: this.$route.query.id,
                item_name: this.Prouductdata.name,
                price: this.activePrice.special_amount,
                quantity: this.num
              }
            ]
          }
          this.$gtag.event('purchase_now', event_config);
          let obj = {
            id: this.$route.query.id,
            price_id: this.activeProduct,
            num: this.num
          }
          list.push(obj)
          let data = {
            plan: list,
            step: 1
          }
          this.$router.push({
            path: '/closing',
            query: {
              data: JSON.stringify(data)
            }
          })
        } else if (!this.activeProduct) {
          this.$message({
            type: 'warning',
            message: '請選擇方案規格'
          })
        } else if (this.num < 1) {
          this.$message({
            type: 'warning',
            message: '請選擇數量'
          })
        }
      }
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },

    //獲取產品詳情
    async getPrcoduct() {
      let data = {
        id: this.$route.query.id
      }
      let res = await getProductDetails(data);
      this.Prouductdata = res.data;
    },

    setMeta(property, content) {
      const existingMeta = document.querySelector(`meta[property="${property}"]`)
      if (existingMeta) {
        existingMeta.setAttribute('content', content)
      } else {
        const meta = document.createElement('meta')
        meta.setAttribute('property', property)
        meta.setAttribute('content', content)
        document.head.appendChild(meta)
      }
    },

    async getGroupByDays() {
      const res = await getGroupByDays({id: this.$route.query.id})
      this.Prouductdata = res.data
      // this.$nextTick(() => {
      //   this.send_to_page()
      // })
    },

    async getCurrentRate() {
      const res = await getCurrentRate({})
      // console.log(Number(res.data.rate))
      this.rate = Number(res.data.rate)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding-bottom: 0;
}

::v-deep .el-dialog {
  width: 580px;
  padding: 20px 40px;
  border-radius: 10px;


  .el-form-item__content {
    margin-left: 0 !important;
  }

  ::v-deep .el-rate__icon {
    font-size: 24px !important;
  }

  .el-input__inner, .el-textarea__inner {
    background: #eeeeee;
    border-radius: 10px;
    border: none;
  }

  .el-form-item {
    p {
      margin: 0;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
    }

    .el-button {
      background: #0B57C9;
      padding: 9px 33px;
      border-radius: 10px;
      font-size: 14px;
      color: #ffffff;

    }
  }

  .dialogTop {
    display: flex;
    align-items: center;
    gap: 40px;

    img {
      width: 173px;
      height: 116px;
      border-radius: 5px;
    }

    p {
      font-size: 20px;
      color: #272727;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

::v-deep .el-dialog__header {
  padding: 0;
  text-align: center;
  font-size: 24px;
  color: #272727;
  font-weight: bold;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #272727;
}


.pinglun {
  display: flex;
  align-items: start;
  margin-top: 30px;

  .pLeft {
    flex: 1;
    margin-right: 80px;

    .pTop {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .avgPjLeft {
        display: flex;
        align-items: center;

        div:first-child {
          margin-right: 20px;

          span {
            color: #FFC700;
            font-size: 36px;
            line-height: 52px;
          }

          span:last-child {
            color: #666666;
            font-size: 25px;
            line-height: 36px;
          }
        }

        div:last-child {
          p {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }

      .avgPjRight {
        .el-button {
          font-size: 16px;
          padding: 8px 25px;

          img {
            vertical-align: -4px;
            margin-right: 10px;
          }
        }
      }
    }

    .pBody {
      margin-top: 30px;
      background: #ffffff;
      border-radius: 10px;
      padding: 20px 40px;
      display: flex;
      align-items: center;

      .bodyTop {
        white-space: nowrap;
        margin-right: 20px;

        .name {
          font-size: 16px;
          line-height: 23px;
          color: #272727;
        }

        .el-rate {
          margin: 5px 0;
        }

        .rate p {
          font-size: 14px;
          line-height: 20px;
          color: #666666;

        }
      }

      .bodyRight {
        h3 {
          font-size: 20px;
          line-height: 29px;
          color: #272727;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 23px;
          color: #272727;
          word-break: break-word;
        }
      }
    }

    .el-pagination {
      display: block;
    }

    .more {
      display: none;
    }
  }

  .pRight {
    width: auto;

    .paixu {
      display: flex;
      align-items: center;
      color: #ffffff;
      white-space: nowrap;
      font-size: 18px;
      margin-bottom: 40px;
      border: 1px solid #ffffff;
      padding-left: 15px;
      border-radius: 9px;

      ::v-deep .el-select {
        width: 100%;
      }

      ::v-deep .el-input__inner {
        background: transparent;
        color: #ffffff;
        font-size: 18px;
        border: none;
        width: 140px;
      }
    }

    .paiBtn {
      .el-button {
        width: 205px;
        display: block;
        background: transparent;
        color: #ffffff;
        border-radius: 25px;
        border-color: #ffffff;
        margin-top: 20px;
        margin-left: 0;
      }

      .active {
        border-color: #FFC700;
        color: #FFC700;
      }
    }
  }
}

.pc {
  display: none;
}

::v-deep .el-rate__icon {
  font-size: 32px;
  margin-right: -3px;
}

::v-deep .el-rate {
  height: 32px;
}

@media screen and (max-width: 800px) {
  .el-backtop {
    display: none;
  }

  ::v-deep .el-dialog {
    width: 350px;
    padding: 20px 0;

    .dialogTop {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .details-intro {
    display: none;
  }

  .details-intro.pc {
    display: block;
  }

  .pinglun {
    flex-wrap: wrap;
    position: relative;

    .pLeft {
      .pTop {
        display: block;
        margin-bottom: 170px;

        .avgPjLeft {
          justify-content: center;
        }

        .avgPjRight {
          width: 100vw;
          text-align: center;
          margin-top: 20px;

          .el-button {
            width: 90vw;
          }
        }
      }

      .pBody {
        flex-wrap: wrap;
        width: 90vw;
        padding: 20px;
        background: transparent;
        margin-top: 10px;

        .bodyTop {
          width: 90vw;
          position: relative;
          margin-bottom: 10px;

          .name {
            color: #ffffff;
          }

          .el-rate {
            margin: 5px 0;
          }

          .rate p {
            font-size: 14px;
            line-height: 20px;
            color: #eeeeee;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        .bodyRight {
          h3 {
            font-size: 18px;
            line-height: 25px;
            color: #ffffff;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            color: #eeeeee;
          }
        }
      }

      .more {
        display: block;
        width: 100vw;
        margin: 20px auto;
        text-align: center;

        .el-button {
          background: transparent;
          width: 90vw;
          color: #ffffff;
          font-size: 16px;
          padding: 13px 52px;
          border-radius: 10px;
        }
      }

      .el-pagination {
        display: none;
      }
    }


    .pRight {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: center;
      width: 100vw;
      position: absolute;
      top: 120px;

      .paixu {
        width: 90vw;
        order: 1;
        margin-bottom: 0;
        background: #ffffff;
        color: #000000;

        ::v-deep .el-input {
          display: flex;
        }

        ::v-deep .el-input__inner {
          flex: 1;
          color: #000000;
        }
      }

      .paiBtn {
        width: 93vw;
        order: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        overflow-x: scroll;
        margin-bottom: 20px;
      }
    }
  }

}

.details {
  padding: 46px 0;
  margin: 0 auto;
  width: 1134px;


  .details-h2 {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .details-content {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 500px;
      height: 335px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content-right {
      padding: 16px 25px;
      width: 568px;
      //height: 303px;
      background: #1D1D1D;
      border-radius: 10px;

      .content-right-h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #FFFFFF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-h2 {
        margin: 6px 0 16px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #00A2FD;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-select {
        display: flex;
        align-items: center;

        span {
          margin-right: 24px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFFFFF;
        }

        /deep/ .el-select {
          width: 170px;
          height: 30px;
          background: #F2F2F2;
          border-radius: 5px;

          .el-input {
            width: 100%;
            height: 100%;

            .el-input__inner {
              width: 100%;
              height: 100%;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #000000;
            }

            .el-input__icon {
              line-height: 30px;
            }
          }
        }
      }

      .content-right-price {
        margin: 10px 0 16px;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #FFFFFF;

        .oldPrice {
          span {
            margin-left: 22px;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            text-decoration-line: line-through;
            color: #616161;
          }
        }

        .newPrice {
          margin-left: 20px;
          display: flex;
          align-items: center;

          p {
            margin-left: 10px;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #F74343;

            span {
              font-size: 24px;
              line-height: 30px;
            }

            i {
              font-size: 14px;
              line-height: 30px;
              font-style: normal;
              margin-left: 10px;
            }
          }
        }
      }

      .content-right-num {
        display: flex;

        .counter {
          display: flex;
          align-items: center;
          min-width: 86px;
          height: 34px;
          background: #F2F2F2;
          border-radius: 5px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;

          span {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 100%;
            font-size: 18px;
            line-height: 34px;
          }

          div {
            min-width: calc(100% - 56px);
            text-align: center;
          }
        }

        .loves {
          display: none;
        }

        .share {
          display: none;
        }
      }

      .content-right-button {
        margin: 25px 0 23px;
        display: flex;
        align-items: center;

        div {
          cursor: pointer;
          user-select: none;
          width: 145px;
          height: 37px;
          border-radius: 20px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button1 {
          background: #0B57C9;
        }

        .button2 {
          margin-left: 5px;
          background: #219D71;
        }
      }

      .content-right-share {
        display: flex;
        align-items: center;

        div {
          cursor: pointer;
          user-select: none;
          margin-right: 32px;
          display: flex;
          align-items: center;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #D9D9D9;

          img {
            margin-right: 9px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .details-tag {
    margin: 20px 0 35px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #B9E6FF;

    span {
      margin-right: 19px;
      cursor: pointer;
    }
  }

  .details-tab {
    display: flex;
    align-items: center;

    .tab-div {
      cursor: pointer;
      user-select: none;
      margin-right: 36px;
      text-align: center;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      color: #D9D9D9;

      span {
        margin-top: 6px;
        display: block;
        width: 97px;
        height: 5px;
        background: transparent;
        border-radius: 5px;
      }
    }

    .tab-active {
      font-weight: 500;
      font-size: 24px;

      span {
        background: linear-gradient(90deg, #00A2FD 8.25%, #A0DDFF 100%);
      }
    }
  }

  .details-intro {
    padding: 30px 0 0;
    width: 867px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 1280px) {
  .details {
    width: 1000px;

    .details-content {
      .content-right {
        width: calc(100% - 568px);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .details {
    width: 780px;

    .details-content {
      flex-wrap: wrap;

      .content-left {
        width: 100%;
      }

      .content-right {
        margin-top: 18px;
        width: calc(100% - 50px);
      }
    }

    .details-intro {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .details {
    padding: 0;
    width: 100%;

    .details-h2 {
      display: none;
    }

    .details-content {
      flex-wrap: wrap;

      .content-left {
        width: 100%;
        height: 250px;
        border-radius: 0;
      }

      .content-right {
        padding: 0 20px;
        margin-top: 0;
        width: calc(100% - 40px);
        height: auto;
        background: none;

        .content-right-h1 {
          margin-top: 10px;
          white-space: normal;
          overflow: visible;
          text-overflow: auto;
        }

        .content-right-h2 {
          margin: 5px 0 12px;
          white-space: normal;
          overflow: visible;
          text-overflow: auto;
        }

        .content-right-select {
          display: block;

          span {
            margin-bottom: 8px;
            display: block;
          }

          /deep/ .el-select {
            width: 100%;
            height: 38px;

            .el-input {
              .el-input__inner {
                line-height: 38px;
              }

              .el-input__icon {
                line-height: 38px;
              }
            }
          }
        }

        .content-right-price {
          margin: 8px 0 13px;

          .oldPrice {
            span {
              margin-left: 9px;
              font-size: 16px;
              line-height: 23px;
            }
          }

          .newPrice {
            margin-left: 20px;

            p {
              i {
                font-size: 12px;
              }
            }

          }
        }

        .content-right-num {
          .loves {
            cursor: pointer;
            user-select: none;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            background: #F2F2F2;
            border-radius: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }

          .share {
            cursor: pointer;
            user-select: none;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            background: #F2F2F2;
            border-radius: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }

        .content-right-button {
          margin: 32px 0 64px;

          div {
            width: calc((100% - 7px) / 2);
          }
        }

        .content-right-share {
          display: none;
        }
      }
    }

    .details-tag {
      margin: 0 0 26px;
      text-align: center;

      span {
        margin: 0 10px;
      }
    }

    .details-tab {
      padding: 0 20px;
      justify-content: space-between;

      .tab-div {
        margin-right: 0;
      }
    }

    .details-intro {
      padding: 20px;
      width: calc(100% - 40px);
    }
  }
}
</style>
