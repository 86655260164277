<template>
  <div class="details">
    <div class="main-content">
      <div class="support-title tutorial-title">
        如何使用eSIM
      </div>
      <div class="support-body tutorial-body">
        <h5>如果您使用的是iOS設備，您可以按照以下步驟進行操作:</h5>
        <p>1. 前往「設定＞行動服務」，點選 「加入eSIM」</p>
        <p>2.	出現掃描方框時將對準QR Code開始掃描即可進行安裝 </p>
        <p>此外若您無法使用相機掃描安裝，也可按以上步驟並選擇「手動輸入詳細資訊」並依序輸入郵件收到的「SM-DP+位址資訊」以及「啟用碼」點擊下一步即可進行安裝</p>
        <br />
        <p>若要查看更多有關APPLE iOS版本的詳細資訊，請點擊這裡: </p>
        <p><a href="https://support.apple.com/zh-tw/HT212780">https://support.apple.com/zh-tw/HT212780</a></p>
        <p>如果您無法確定您的設備是否支持eSIM，您可以先到「設定＞一般＞關於本機＞電信業者鎖定」檢查是否有SIM卡限制，若無限制再到「設定＞行動服務＞加入行動方案」 透過掃描QR Code開始設定eSIM</p>
        <br />
        <br />
        <h5>如果您使用的是 Android 設備，您可以按照以下步驟進行操作:</h5>
        <p>1. 前往「設定＞網路與網際網路」，點選「SIM卡」旁邊的 “＋”</p>
        <p>2.	下方有「要改為下載 SIM 卡嗎？」且該選項在點擊後要求授權相機掃描QR Code，則對準QR Code開始掃描即可進行安裝</p>
        <p>此外若您無法使用相機掃描安裝，也可按以上步驟並選擇下方「需要協助嗎？」並點擊「手動輸入」接下來就可輸入郵件收到的「SM-DP+位址資訊＋啟用碼」進行安裝</p>
        <br />
        <p>若在您的手機上找不到相關新增eSIM/ 下載SIM....等字眼，那您的手機可能不支援eSIM，還請選擇其他的方案，例如實體網卡或是選擇電信商的漫遊功能，請勿下單eSIM 方案，謝謝</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>


</style>
