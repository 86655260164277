<template>
  <div class="main-content works">
    <h4 class="textTitle">簡單安裝三步驟</h4>
    <div class="row col-1 md-3 easy">
      <div class="col">
        <div class="imgBox"><img src="@/assets/image/works/s1.png" alt=""></div>
        <h6>選擇並購買</h6>
        <p>在eSIMart找到您的目的地國家以及最適合您旅遊計畫的天數方案，並完成下單</p>
      </div>
      <div class="col">
        <div class="imgBox"><img src="@/assets/image/works/s2.png" alt=""></div>
        <h6>掃描QR Code</h6>
        <p>完成下單15分鐘即可收到QR code，將QR code掃描進支援eSIM的手機內，即可簡單快速啟動方案．</p>
      </div>
      <div class="col">
        <div class="imgBox"><img src="@/assets/image/works/s3.png" alt=""></div>
        <h6>高速上網</h6>
        <p>抵達目的地國家，開啟eSIM漫遊後就可以開始享受高速網路並愉快旅遊．</p>
      </div>
    </div>

    <div class="video-box">
      <p class="des">以下是逐步指示，但您也可能會發現我們的視頻指南有所幫助</p>

      <div class="iframeBox">
        <iframe src="https://www.youtube.com/embed/ni1BKd1xmHs" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
      </div>

    </div>


    <div class="esim-wrap" ref="herf">
      <div class="header">
        <div class="button1" :class="{'active':tabIndex === 1}" @click="tabIndex = 1;ios = true;">iOS 安裝說明</div>
        <div class="button1" :class="{'active':tabIndex === 2}" @click="tabIndex =2;ios = false;">Android 安裝說明</div>
      </div>


      <div style="display: flex;align-items: center;flex-wrap: wrap">
        <div class="btn-ico">
          <div class="_btn topImg">
            <h4 style="flex: 1">
              <span>出國前-安裝</span>
            </h4>
            <img src="@/assets/image/works/ico-1.png">
          </div>
        </div>
        <p class="des laP">需要在穩定網路連線環境下進行安裝
          <img src="@/assets/image/works/Star 1.png">
          <span class="pc"></span>
          一張eSIM只能安裝在一台手機上
          <span class="botn"></span></p>
      </div>
      <div class="new-box" v-if="ios">
        <div class="row md-3">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">掃描QR code</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">允許啓用相機</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios2.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">準備安裝</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios3.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">完成安裝</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios4.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">預設號碼 選擇「 主要 」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios5.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">6</span>
              <span class="_name">網路通訊軟體 選擇「主要」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios7.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">7</span>
              <span class="_name">行動數據選擇「主要」 記得關閉行動數據切換</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios6.png" alt="">
            </div>
          </div>
          <div class="col " style="display: flex;align-items: center;flex-wrap: wrap">
            <div class="btn-ico btn-ico-wrap">
              <div class="_btn topImgTwo">
                <h4 style="flex: 1">
                  <span>抵達後-切換</span>
                </h4>
                <img class="uImg" src="@/assets/image/works/ios-2.png"/>
              </div>
            </div>
            <p style="font-size: 18px">出發前，請先關閉「eSIM方案」及「數據漫遊」</p>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">8</span>
              <span class="_name">預設號碼
選擇「 次要 」開啟漫遊</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios8.png" alt="">
            </div>
          </div>
        </div>

        <div class="btn-title-wrap">
          <h4>iOS安裝說明-手動安裝</h4>
        </div>


        <div class="row md-3 title-wrap-box">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">請在連接可用WiFi情況下選擇「行動服務」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.1.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">點選「加入eSIM」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.2.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">點選「使用行動條碼」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.3.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">點選「手動輸入詳細資訊</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.4.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">複製並貼上郵件中提供的 SM-DP+ Address 與 Activation code，並點擊「下一步」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.5.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="new-box" v-if="!ios">
        <div class="row md-3">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">請確認連接WiFi後開啟「設定」→「網路和網際網路」」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">點擊 SIM 卡旁
的「+」符號</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android2.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">點擊「要改為下載SIM卡」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android3.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">點擊「繼續」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android4.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">掃描 QR code，點擊「下載」</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android5.png" alt="">
            </div>
          </div>
          <div class="col " style="align-self: center">
            <div class="btn-ico btn-ico-wrap">
              <div class="_btn">
                <img class="uImg" src="@/assets/image/works/ios-2.png"/>
                <h4>
                  <span>抵達後-切換</span>
                </h4>
              </div>
            </div>
            <p style="font-size: 18px">出發前，請先關閉「eSIM方案」及「數據漫遊」</p>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">6</span>
              <span class="_name">點擊「設定」選擇 eSIM，並開啟「使用 SIM 卡」及漫遊</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android6.png" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">7</span>
              <span class="_name">關閉不需使用的方案</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android7.png" alt="">
            </div>
          </div>

          <div class="btn-title-wrap">
            <h4>Android安裝說明-手動安裝</h4>
          </div>


          <div class="row md-3 title-wrap-box">
            <div class="col">
              <h4 class="_title">
                <span class="_num">1</span>
                <span class="_name">請確認連接WiFi後開啟「設定」→「網路和網際網路」</span>
              </h4>
              <div class="_imgBox">
                <img src="@/assets/image/works/android1.1.png" alt="">
              </div>
            </div>
            <div class="col">
              <h4 class="_title">
                <span class="_num">2</span>
                <span class="_name">點擊 SIM 卡旁的「+」符號</span>
              </h4>
              <div class="_imgBox">
                <img src="@/assets/image/works/android1.2.png" alt="">
              </div>
            </div>
            <div class="col">
              <h4 class="_title">
                <span class="_num">3</span>
                <span class="_name">點擊「要改為下載SIM卡」</span>
              </h4>
              <div class="_imgBox">
                <img src="@/assets/image/works/android1.3.png" alt="">
              </div>
            </div>
            <div class="col">
              <h4 class="_title">
                <span class="_num">4</span>
                <span class="_name">點擊「需要協助嗎？」</span>
              </h4>
              <div class="_imgBox">
                <img src="@/assets/image/works/android1.4.png" alt="">
              </div>
            </div>
            <div class="col">
              <h4 class="_title">
                <span class="_num">5</span>
              </h4>
              <div class="_imgBox">
                點擊「手動輸入」複製並貼上郵件中提供的啟動碼，並點擊「繼續」即可下載
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search: '',
      tabIndex: 1,
      searchTwo: '',
      ios1: [
        // {
        //   id: 1,
        //   name: "Use Camera to scan QRcode from eSImart",
        //   img: require("@/assets/image/works/ios1.jpg")
        // },
        // {
        //   id: 2,
        //   name: "Tap Continue to Activate eSIM at your phone",
        //   img: require("@/assets/image/works/ios2.jpg")
        // },
        // {
        //   id: 3,
        //   name: "Add cellular plan and label it",
        //   img: require("@/assets/image/works/ios3.jpg")
        // },
        // {
        //   id: 4,
        //   name: "Choose ‘Primary’ for Default Line",
        //   img: require("@/assets/image/works/ios4.jpg")
        // },
        // {
        //   id: 5,
        //   name: "Choose ‘Primary’ for iMessage & FaceTime",
        //   img: require("@/assets/image/works/ios5.jpg")
        // },
        // {
        //   id: 6,
        //   name: "Choose ‘Primary’ for Cellular Data",
        //   img: require("@/assets/image/works/ios6.jpg")
        // },
      ],
      // 产品列表
      dataList: [],
      ios: true
    };
  },
  created() {

  },
  mounted() {
    if (this.$route.query.name && this.$route.query.name == 'herf') {
      const el = this.$refs.herf
      this.$nextTick(() => {
        if (el) {
          el.scrollIntoView({behavior: 'smooth', block: 'start'})
        }

      })
    }
  },
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped>
p {
  color: #ffffff;
}
.topImg{
  position: relative;
  img{
    width: 60px;
    height: 90px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.topImgTwo{
  position: relative;
  span{
    width: calc(100% - 50px);
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }

  img{
    width: 50px;
    height: 70px;
    object-fit: contain;
    position: relative;
    top: 0px;
    right: 0;
  }
}
@media screen and (max-width: 768px){
  .topImgTwo{
    position: relative;
    text-align: center;
    span{
      width: calc(100% - 40px);
      position: absolute;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
    }
    img{
      width: 40px;
      height: 40px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .topImg{
    position: relative;
    img{
      width: 50px;
      height: 70px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
</style>
