<template>
  <div class="main-content affiliate">
    <h4 class="textTitle">加入我們</h4>
    <h2 class="title">探索無限可能的未來</h2>
    <div class="affiliate-list-box">
      <div class="row md-2 future affiliate-list">
        <div class="col _textP">
          <p> 我們非常榮幸向您介紹eSImart，覆蓋全球網路通訊的可能性是無限的。</p>
          <p> 隨著世界日益緊密相連，保持聯繫不再是一種奢侈，而是一種必要。這就是我們的eSIM技術發揮作用的地方。</p>
        </div>
        <div class="col _icoImg">
          <img src="@/assets/image/affiliate/ico-saly.png" />
        </div>
      </div>
      <div class="row md-2 fast-paced affiliate-list">
        <div class="col _textP">
          <p> 在這個快節奏的數位時代，傳統的實體 SIM 卡可能會讓人感到麻煩、受限，並且常常限制了我們在行動時保持連接的能力。然而，有了 eSIM 技術，我們已經徹底改變了人們訪問和管理他們的移動連接的方式。</p>
        </div>
        <div class="col _icoImg">
          <div class="ico-frame">
            <img class="frame-1" src="@/assets/image/affiliate/frame-1.png" />
            <img class="frame-2" src="@/assets/image/affiliate/frame-2.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="container-shifting">


    </div>


    <div class="site-message">
      <div class="round-dot">
        <div class="_dot"></div>
        <div class="_dot"></div>
        <div class="_dot"></div>
      </div>
      <div class="_box">
        <div class="_max-container">
          <p>在我們的eSIM網站上，我們提供各種eSIM解決方案，讓個人和企業可以跨國界輕鬆享受無縫連接。我們用戶友好的平台使客戶能夠輕鬆下載和激活其設備的eSIM配置文件，消除了對物理SIM卡的需求，提供了更大的靈活性和便利性。</p>

          <p>但我們並不止步於此。我們正在積極尋找與我們有著對一個無界限世界的共同願景，並且熱衷於為他們的客戶提供優秀連接解決方案的合作夥伴。通過與我們合作，您可以開拓一個迅速增長的市場，並為您的客戶提供獨特的價值主張。</p>

        </div>
      </div>
    </div>

    <div class="as-box">
      <div class="as-wrap">
        <p>作為聯盟合作夥伴，您將獲得完整的行銷資料、資源，以及我們專業團隊的支持。我們將與您密切合作，制定與您的受眾和業務目標相符的定制策略，確保雙方共同成功。</p>

        <p>與我們攜手合作意味著成為一個塑造連接未來的動態生態系統的一部分。共同努力，我們可以開拓新機遇，擴展影響力，並提升客戶體驗。無論您是科技影響者、電信提供商、電子商務平台，或任何其他行業的專業人士，我們相信合作有著巨大的潛力。</p>
      </div>
      <div class="round-dot">
        <div class="_dot"></div>
        <div class="_dot"></div>
        <div class="_dot"></div>
      </div>
    </div>
    <div class="so-box">
      <div class="so-wrap">
        <div class="so-ico-box"></div>
        <p>作為聯盟合作夥伴，您將獲得一套全面的營銷材料、資源，以及我們專業團隊的支援。我們將與您密切合作，開發符合您的受眾和業務目標的定制策略，確保雙方的成功。</p>

        <p>與我們合作，意味著成為塑造連接未來的動態生態系統的一部分。一起，我們可以開拓新的機會，擴展影響力，並提升客戶體驗。無論您是科技影響者、電信供應商、電子商務平台還是其他行業專業人士，我們相信合作有著巨大的潛力。</p>
      </div>
    </div>


    <a href="/affiliateSign" class="affiliate-btn"><span>立即加入</span></a>





  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      tabIndex:1,
      searchTwo:'',
      // ios1:[
      //   {
      //     id: 1,
      //     name: "Use Camera to scan QRcode from eSImart",
      //     img: require("@/assets/image/works/ios1.jpg")
      //   },
      //   {
      //     id: 2,
      //     name: "Tap Continue to Activate eSIM at your phone",
      //     img: require("@/assets/image/works/ios2.jpg")
      //   },
      //   {
      //     id: 3,
      //     name: "Add cellular plan and label it",
      //     img: require("@/assets/image/works/ios3.jpg")
      //   },
      //   {
      //     id: 4,
      //     name: "Choose ‘Primary’ for Default Line",
      //     img: require("@/assets/image/works/ios4.jpg")
      //   },
      //   {
      //     id: 5,
      //     name: "Choose ‘Primary’ for iMessage & FaceTime",
      //     img: require("@/assets/image/works/ios5.jpg")
      //   },
      //   {
      //     id: 6,
      //     name: "Choose ‘Primary’ for Cellular Data",
      //     img: require("@/assets/image/works/ios6.jpg")
      //   },
      // ],
      // 产品列表
      dataList: [

      ],
      ios:true
    };
  },
  created() {

  },
  methods: {

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>

</style>
